import { Injectable } from "@angular/core";
import { IMenuItem } from "@aecom/core";
import { ProjectNavigation, TPContract } from "../api-generated";
import BaseService from "./base.service";

@Injectable({
  providedIn: "root",
})
export default class LocalContractService extends BaseService<TPContract> {
  public currentContract: TPContract;

  public setItem(
    item: TPContract | IMenuItem | ProjectNavigation | null,
  ): void {
    let contract: TPContract | null = null;

    if (Array.isArray(item) && item.length) {
      contract = item[0] as unknown as TPContract;
    } else if (!Array.isArray(item)) {
      contract = item as unknown as TPContract;
    }

    if (contract && (contract as unknown as { contract: unknown }).contract) {
      contract = (contract as unknown as { contract: TPContract }).contract;
    }

    super.setItem(contract);
  }
}
