import {
  ISelectItem,
  ModalContainerService,
  NotificationComponent,
} from "@aecom/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CorrespondenceItemService,
  ICorrDocumentDownload,
  ICorrListItem,
} from "@api";
import AuthService from "@auth/auth.service";
import ColumnType from "@models/columnType";
import CorrespondenceRole from "@models/CorrespondenceRole";
import CorrIssue from "@models/corrIssue";
import IContractUserWithUserInfo from "@models/IContractUserWithUserInfo";
import IViewPrepare from "@models/IViewPrepaer";
import LoadingService from "@services/loading.service";
import LocalContractUserService from "@services/local-contractUser.service";
import LocalCorrespondenceTypeService from "@services/local-correspondenceType.service";
import tableHeader from "@shared/selectUser/table-header";
import TableHeaderCol from "@shared/selectUser/table-header-col";
import RowItem from "@shared/selectUser/table-row";
import {
  corrDBRoldId,
  corrDocControlRoleId,
  correspondenceAppId,
  corrManagerRoleId,
} from "@shared/staticValue";
import {
  convertDateToUTCIOSString,
  errorHandle,
  generateUserRowItem,
  getDateWithOffSet,
} from "@shared/utils";
import _ from "lodash";
import { Observable } from "rxjs";

@Component({
  selector: "app-issued",
  templateUrl: "./issued.component.html",
  styleUrls: ["./issued.component.scss"],
})
export default class IssuedComponent implements OnInit {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  entity: ICorrListItem;

  currentUser: string;

  defaultTab = "details";

  docs: ICorrDocumentDownload[] = [];

  isDbAdmin = false;

  corr: CorrIssue;

  oldCorr: CorrIssue;

  managersList: ISelectItem[] = [];

  corrTypeList: ISelectItem[] = [];

  canIssue = false;

  dueDate: Date | null = null;

  receivedDate: Date | null = null;

  today: Date;

  allUserTableRows: RowItem[] = [];

  ccUsersTableData: RowItem[] = [];

  ccUsersTableRows: RowItem[] = [];

  showSelectUser = false;

  tableRows: RowItem[] = [];

  firstOpen = true;

  tableHeader: TableHeaderCol[] = tableHeader;

  activeSave = false;

  columnType = ColumnType;

  allTags: string[] = ["Apple", "Lemon", "Lime", "Orange", "Strawberry"];

  enableSaveDraft = false;

  constructor(
    public router: Router,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public localContractUserService: LocalContractUserService,
    public localCorrespondenceTypeService: LocalCorrespondenceTypeService,
    public activeModal: ModalContainerService,
    public correspondenceItemService: CorrespondenceItemService,
  ) {}

  ngOnInit(): void {
    const data: IViewPrepare | undefined =
      this.activatedRoute.snapshot.data.itemData;
    this.currentUser = this.authService.user.Id;
    this.today = new Date();
    if (data) {
      const { item, documents, topics } = data;
      this.entity = item;
      this.docs = documents;
      this.allTags = topics;
      this.oldCorr = new CorrIssue(this.entity.ContractId, this.entity);
      this.dueDate = this.oldCorr.correspondence_issued.DueDate
        ? new Date(
            getDateWithOffSet(this.oldCorr.correspondence_issued.DueDate),
          )
        : null;
      this.receivedDate = this.oldCorr.correspondence_issued.ReceivedDate
        ? new Date(
            getDateWithOffSet(this.oldCorr.correspondence_issued.ReceivedDate),
          )
        : null;
      this.managersList = this.localContractUserService
        .getUsers([CorrespondenceRole.Manager])
        .map((u) => {
          return {
            id: u.UserId,
            name: u.UserInfo.displayName ?? "",
            checked: false,
          };
        });
      this.corrTypeList = this.localCorrespondenceTypeService
        .getItems()
        .filter((u) => {
          return u.InActive !== true;
        })
        .sort((a, b) => (a.Title > b.Title ? 1 : -1))
        .map((u) => {
          return {
            id: u.Guid,
            name: u.Title,
            checked: false,
          };
        });
      console.log(this.managersList);
      this.allUserTableRows = this.localContractUserService
        .getItems()
        .filter((user) => {
          return user.contract_user_application_role.find((role) => {
            return (
              role.ApplicationId === correspondenceAppId &&
              role.ApplicationRoleId !== corrDBRoldId &&
              role.ApplicationRoleId !== corrDocControlRoleId
            );
          });
        })
        .map((u) => {
          return generateUserRowItem<IContractUserWithUserInfo>(u);
        });
      this.ccUsersTableData = this.allUserTableRows.filter((u) => {
        return this.oldCorr.correspondence_cc_user.includes(u.id);
      });
    }

    this.corr = JSON.parse(JSON.stringify(this.oldCorr));
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldCorr);
    const current = JSON.stringify(this.corr);
    return old !== current;
  }

  removeUserClick(row: RowItem): void {
    this.ccUsersTableData = this.ccUsersTableData.filter(
      (reviewer) => reviewer.id !== row.id,
    );
    this.corr.correspondence_cc_user = this.ccUsersTableData.map((u) => {
      return u.id;
    });
    this.enableSaveDraft = this.hasChanges();
  }

  openWindowClick(): void {
    this.activeSave = false;

    this.tableRows = this.allUserTableRows
      .filter((user) => {
        return (
          user.id !== this.corr.correspondence_issued.ManagerId &&
          !this.corr.correspondence_cc_user.includes(user.id)
        );
      })
      .map((user) => {
        user.checked = false;
        return user;
      });

    this.showSelectUser = true;
  }

  closeWindow(e?: RowItem[]): void {
    if (this.firstOpen || !e) {
      this.firstOpen = false;
    } else if (this.activeSave) {
      if (e && Array.isArray(e) && e.length > 0) {
        this.ccUsersTableData = this.ccUsersTableData.concat(e);
        this.corr.correspondence_cc_user = this.ccUsersTableData.map((u) => {
          return u.id;
        });

        this.firstOpen = true;
        this.showSelectUser = false;
        this.enableSaveDraft = this.hasChanges();
      } else {
        const modalInstance = this.activeModal.open(NotificationComponent);
        modalInstance.instance.theme = "light";
        modalInstance.instance.title = "Notification";
        modalInstance.instance.body = "Are you sure you would like to cancel?";

        modalInstance.result.then(async (result) => {
          this.firstOpen = true;
          if (result === 1) {
            this.showSelectUser = false;
          }
        });
      }
    } else {
      this.firstOpen = true;
      this.showSelectUser = false;
    }
  }

  back(): void {
    if (this.hasChanges()) {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Cancel editing?";
      modalInstance.instance.body = "Your changes will not be saved.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.refreshEntity();
          this.router.navigateByUrl(`${this.entity.ContractId}/list`);
        }
      });
    } else {
      this.router.navigateByUrl(`${this.entity.ContractId}/list`);
    }
  }

  IsValid(): boolean {
    return !_.isEmpty(this.corr.correspondence_issued.ManagerId);
  }

  submit(): void {
    this.canIssue = true;

    // const validation = this.IsValid();

    // if (!validation) {
    //   return;
    // }

    this.corr.correspondence_issued.IsDraft = false;
    this.update();
  }

  save(): void {
    this.canIssue = true;

    this.corr.correspondence_issued.IsDraft = true;
    this.update();
  }

  update(): void {
    this.loadingService.start();
    if (this.corr.correspondence_issued.ReceivedToday) {
      this.corr.correspondence_issued.ReceivedDate = null;
    }
    if (!this.corr.correspondence_issued.RequireResponse) {
      this.corr.correspondence_issued.DueDate = null;
    }
    if (
      this.corr.correspondence_issued.CorrespondenceTypeId &&
      this.corr.correspondence_issued.CorrespondenceTypeId.trim() === ""
    ) {
      this.corr.correspondence_issued.CorrespondenceTypeId = null;
    }
    this.correspondenceItemService.corrUpdate(this.corr).subscribe(
      async (r) => {
        this.refreshEntity();
        this.loadingService.stop();
        this.router.navigateByUrl(`${this.entity.ContractId}/list`);
      },
      (error: HttpErrorResponse) => {
        this.refreshEntity();
        this.loadingService.stop();
        errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  refreshEntity(): void {
    this.oldCorr = this.corr;
  }

  setRequireResponse(e: boolean): void {
    this.corr.correspondence_issued.RequireResponse = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setReceivedToday(e: boolean): void {
    this.corr.correspondence_issued.ReceivedToday = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setManager(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.corr.correspondence_issued.ManagerId = e?.id;
      this.ccUsersTableData = this.ccUsersTableData.filter((item) => {
        return item.id !== e?.id;
      });
      this.corr.correspondence_cc_user = this.ccUsersTableData.map((u) => {
        return u.id;
      });
      this.enableSaveDraft = this.hasChanges();
    }
  }

  setCorrType(e: ISelectItem): void {
    if (e && !Array.isArray(e) && !("type" in e)) {
      console.log(e);
      this.corr.correspondence_issued.CorrespondenceTypeId = e?.id;
      this.enableSaveDraft = this.hasChanges();
    }
  }

  setDate(e: Date | any): void {
    if (_.isNil(e) || e.type === "change") return;
    if (e.target?.value === "") {
      this.dueDate = null;
      this.corr.correspondence_issued.DueDate = null;
    } else {
      this.dueDate = new Date(e);
      this.corr.correspondence_issued.DueDate = convertDateToUTCIOSString(
        this.dueDate,
      );
    }
    this.enableSaveDraft = this.hasChanges();
  }

  setReceivedDate(e: Date | any): void {
    if (_.isNil(e) || e.type === "change") return;
    if (e.target?.value === "") {
      this.receivedDate = null;
      this.corr.correspondence_issued.ReceivedDate = null;
    } else {
      this.receivedDate = new Date(e);
      this.corr.correspondence_issued.ReceivedDate = convertDateToUTCIOSString(
        this.receivedDate,
      );
    }
    this.enableSaveDraft = this.hasChanges();
  }

  setIsConfidential(e: boolean): void {
    this.corr.correspondence_issued.IsConfidential = e;
    this.enableSaveDraft = this.hasChanges();
  }
}
