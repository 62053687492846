/* eslint-disable import/no-extraneous-dependencies */

import { Component, Input } from "@angular/core";
import ManagerResponseDetailItem from "@models/managerResponseDetailItem";

@Component({
  selector: "managerResponseDetail",
  templateUrl: "./managerResponseDetail.component.html",
  styleUrls: ["./managerResponseDetail.component.scss"],
})
export default class ManagerResponseDetailComponent {
  @Input() item: ManagerResponseDetailItem;
}
