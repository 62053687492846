/* eslint-disable import/no-extraneous-dependencies */
import { Component, Input } from "@angular/core";
import { ICorrListItem } from "@api";
import ITimelineItem from "@bbj/components/lib/interfaces/ITimeline";
import CorrespondenceStatus from "@models/CorrespondenceStatus";
import IContractUserWithUserInfo from "@models/IContractUserWithUserInfo";
import ManagerResponseDetailItem from "@models/managerResponseDetailItem";
import ResponseDetailItem from "@models/responseDetailItem";
import LocalContractUserService from "@services/local-contractUser.service";
import { correspondenceAppId } from "@shared/staticValue";
import { getLastestIssuedItem } from "@shared/utils";

@Component({
  selector: "corrProcessing",
  templateUrl: "./corrProcessing.component.html",
  styleUrls: ["./corrProcessing.component.scss"],
})
export default class CorrProcessingComponent {
  @Input() corr: ICorrListItem;

  timelineItems: ITimelineItem[];

  responseDetail: ResponseDetailItem;

  managerResponseDetail: ManagerResponseDetailItem;

  title: string;

  constructor(public localContractUserService: LocalContractUserService){}

  ngOnInit(): void {
    const users = this.localContractUserService.getItems();
    if(this.corr.correspondence_flow && this.corr.correspondence_flow.length > 0)
    {
      this.timelineItems = this.corr.correspondence_flow
        .filter((f)=>f.Status !== CorrespondenceStatus.Draft)
        .sort((a, b) => new Date(a.DateReceived).getTime() - new Date(b.DateReceived).getTime())
        .map((item, i) => {
          const timeSubmitted = item.DateReceived ? this.getFormatedDateAndTime(item.DateReceived) : "N/A";
          const daysInBic = "N/A";
          let initBic = 'Doc Control';
          if(item.BICUserId)
          {
            const initUser: IContractUserWithUserInfo = users.find((u)=>{return u.UserId === item.BICUserId});
            const initUserrole = this.getUserRole(initUser);
            initBic = `${initUser?.UserInfo?.displayName ?? ""} (${initUserrole})`;
          }
          
          const submittedUser: IContractUserWithUserInfo = users.find((u)=>{return u.UserId === item.SubmittedBy});
          const submittedUserRole = this.getUserRole(submittedUser);
          const submittedBy = `${submittedUser?.UserInfo?.displayName ?? ""} (${submittedUserRole})`;
          const status = i === 0 ? "Submitted" : item.Status;
          return { sequence: i + 1, status, bic: initBic, timeSubmitted, daysInBic, submittedBy: submittedBy }
        });
    }
    if(this.corr.correspondence_issued.length > 0)
    {
      const lastIssued = getLastestIssuedItem(this.corr);
      this.responseDetail = new ResponseDetailItem(lastIssued, users);
      if(this.corr.correspondence_response && this.corr.correspondence_response.length > 0) {
        this.managerResponseDetail = new ManagerResponseDetailItem(lastIssued?.ManagerId, this.corr.correspondence_response[0], users);
      }
    }
    
    this.title = this.corr.Status === CorrespondenceStatus.Filed ? "Notes" : "Response Requested";
  }

  private getUserRole(user: IContractUserWithUserInfo): string {
    return user?.contract_user_application_role.find((cr)=>{return cr.ApplicationId === correspondenceAppId})?.application_role?.Role || "";
  }

  private getFormatedDateAndTime(date: string): string {
    return `${new Date(date)
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      })
      .replace(", ", "/")
      .replace(" ", "/")} ${new Date(date).toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit"
    })}`;
  }

  private getDaysBetweenDates(date1: string | undefined, date2: string | undefined): string {
    if(date1 && date2)
    {
      const end = this.keepDateOnly(date1);
      const start = this.keepDateOnly(date2);
      const difference = end.getTime() - start.getTime();
      const TotalDays = Math.round(difference / (1000 * 3600 * 24));
      return TotalDays.toString();
    }
    return "N/A";
  }

  private keepDateOnly(date: string): Date {
    const result = new Date(date);
    result.setHours(0);
    result.setMinutes(0);
    result.setSeconds(0);
    result.setMilliseconds(0);
    return result
  }

}
