enum CorrespondenceRole {
  DBAdmin = '8a013215-0baf-11ea-917a-027e1b1809fa',
  DocControl = '8a013216-0baf-11ea-917a-027e1b1809fa',
  Manager = '8a013217-0baf-11ea-917a-027e1b1809fa',
  CC = 'CC',
  PMC_User = '8a013219-0baf-11ea-917a-027e1b1809fa',
  SA_User = '8a013223-0baf-11ea-917a-027e1b1809fa',
}

export default CorrespondenceRole;
