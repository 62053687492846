<div class="page-header">
  <div class="header-title">
    <p>
      <a [routerLink]="['/' + corr.ContractId + '/list']">
        Correspondence list &gt;
      </a>

      <span>{{ number }}</span>
    </p>

    <h1>
      <i class="icon icon-lg" [ngClass]="icon"></i>
      <span style="text-transform: uppercase">{{ status }}</span>
    </h1>
  </div>

  <div style="flex: 1">
    <div class="header-subTitle">{{ number }}</div>
  </div>
</div>