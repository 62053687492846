import {
  FileModel,
  ISelectItem,
  ModalContainerService,
  NotificationComponent,
  NotificationType,
  UploadStatus,
} from "@aecom/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CorrespondenceDocumentsService,
  CorrespondenceItemService,
  CorrespondenceReferenceUser,
  ICorrListItem,
} from "@api";
import { ICorrDocumentDownload } from "@api/model/iCorrDocumentDownload";
import { ICorrDocumentUploadReturn } from "@api/model/iCorrDocumentUploadReturn";
import AuthService from "@auth/auth.service";
import { ComponentCanDeactivate } from "@guards/pending-changes.guard";
import ColumnType from "@models/columnType";
import CorrCreate from "@models/corrCreate";
import CorrDocumentUpload from "@models/corrDocumentUpload";
import CorrespondenceFileType from "@models/CorrespondenceFileType";
import CorrespondenceRole from "@models/CorrespondenceRole";
import CorrespondenceStatus from "@models/CorrespondenceStatus";
import IContractUserWithUserInfo from "@models/IContractUserWithUserInfo";
import IViewPrepare from "@models/IViewPrepaer";
import AzureBlobService from "@services/azureBlob.service";
import FileManagementService from "@services/fileManagement.service";
import LoadingService from "@services/loading.service";
import LocalContractService from "@services/local-contract.service";
import LocalContractUserService from "@services/local-contractUser.service";
import LocalUserService from "@services/local-user.service";
import tableHeader from "@shared/selectUser/table-header";
import TableHeaderCol from "@shared/selectUser/table-header-col";
import RowItem from "@shared/selectUser/table-row";
import {
  corrDBRoldId,
  corrDocControlRoleId,
  correspondenceAppId,
} from "@shared/staticValue";
import {
  errorHandle,
  generateUserRowItem,
  getDateWithOffSet,
  getFilesByType,
  isFileNameInvalid,
  validateInput,
} from "@shared/utils";
import _, { isString } from "lodash";
import { Observable } from "rxjs";
import environment from "src/environments/environment";

@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export default class CreateComponent implements OnInit, ComponentCanDeactivate {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  title: string;

  corr: CorrCreate;

  oldCorr: CorrCreate;

  attemptToIssue = false;

  fromList: ISelectItem[];

  toList: ISelectItem[];

  contractId: string;

  currentUser: string;

  correspondenceFile: FileModel[] = [];

  attachments: FileModel[] = [];

  oldCorrespondenceFile: FileModel[] = [];

  oldAttachments: FileModel[] = [];

  correspondenceFileNameValid = true;

  attachmentsNameValid = true;

  enableSaveDraft = false;

  LetterDate: Date;

  allUserTableRows: RowItem[] = [];

  ccUsersTableData: RowItem[] = [];

  ccUsersTableRows: RowItem[] = [];

  showSelectUser = false;

  tableRows: RowItem[] = [];

  firstOpen = true;

  tableHeader: TableHeaderCol[] = tableHeader;

  activeSave = false;

  columnType = ColumnType;

  subjectMaxLength = 100;

  referenceMaxLength = 150;

  notesMaxLength = 250;

  private removedFileIds: string[] = [];

  constructor(
    public router: Router,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public localContractuServices: LocalContractService,
    public activeModal: ModalContainerService,
    public correspondenceItemService: CorrespondenceItemService,
    public correspondenceDocumentsService: CorrespondenceDocumentsService,
    public fileManagementService: FileManagementService,
    public localContractUserService: LocalContractUserService,
    public localUserService: LocalUserService
  ) {}

  ngOnInit(): void {
    const data: IViewPrepare | undefined =
      this.activatedRoute.snapshot.data.itemData;
    const refUsers: CorrespondenceReferenceUser[] =
      this.activatedRoute.snapshot.data.createStepData;
    this.currentUser = this.authService.user.Id;
    this.contractId = this.localContractuServices.currentContract.Guid;
    this.allUserTableRows = this.localContractUserService
      .getItems()
      .filter((user) => {
        return user.contract_user_application_role.find((role) => {
          return (
            role.ApplicationId === correspondenceAppId &&
            role.ApplicationRoleId !== corrDBRoldId &&
            role.ApplicationRoleId !== corrDocControlRoleId
          );
        });
      })
      .map((u) => {
        return generateUserRowItem<IContractUserWithUserInfo>(u);
      });
    const userRole = this.localContractUserService.currentUserContractRole;
    const userEmail = this.authService.user.Email;
    if (data) {
      const { item, documents } = data;
      this.loadData(
        refUsers.sort(CreateComponent.compare),
        userRole,
        userEmail,
        item,
        documents,
      );
    } else {
      this.loadData(
        refUsers.sort(CreateComponent.compare),
        userRole,
        userEmail,
      );
    }
    this.loadingService.stop();
  }

  static compare(
    a: CorrespondenceReferenceUser,
    b: CorrespondenceReferenceUser,
  ): number {
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  }

  loadData(
    users: CorrespondenceReferenceUser[],
    userRoles: string,
    userEmail: string | null,
    corr?: ICorrListItem,
    documents?: ICorrDocumentDownload[],
  ): void {
    if (userRoles === CorrespondenceRole.DBAdmin) {
      const contractUsers = this.localContractUserService.getItems();
      const contractDBs: CorrespondenceReferenceUser[] = [];
      const contractAHJVs: CorrespondenceReferenceUser[] = [];
      users.forEach((u) => {
        const user = contractUsers.find((cu) => {
          return cu.UserId === u.UserId && cu.DeletedBy === null;
        });
        if (user) {
          const corrRole = user.contract_user_application_role.find((cuar) => {
            return cuar.ApplicationId === correspondenceAppId;
          });
          if (corrRole) {
            if (corrRole.ApplicationRoleId === corrDBRoldId) {
              contractDBs.push(u);
            } else {
              contractAHJVs.push(u);
            }
          }
        }
      });
      this.fromList = this.mappingRefUsers(contractDBs);
      this.toList = this.mappingRefUsers(contractAHJVs);
    } else {
      this.fromList = this.mappingRefUsers(users);
      this.toList = [...this.fromList];
    }

    const dbUser =
      userRoles === CorrespondenceRole.DBAdmin
        ? users.find(
            (u) =>
              u.Email?.toLocaleLowerCase() === userEmail?.toLocaleLowerCase(),
          )
        : undefined;
    this.oldCorr = new CorrCreate(this.contractId, corr, dbUser?.Guid);
    this.LetterDate = this.oldCorr.correspondence_create?.LetterDate
      ? new Date(
          getDateWithOffSet(this.oldCorr.correspondence_create.LetterDate),
        )
      : null;
    if (this.LetterDate) {
      this.oldCorr.correspondence_create.LetterDate =
        this.LetterDate.toISOString();
    }

    if (documents && documents.length > 0) {
      const documnetsForThis = documents.filter((item) => {
        return item.Status === CorrespondenceStatus.Draft;
      });
      console.log("all documents", documnetsForThis);

      this.oldCorrespondenceFile = getFilesByType(
        documnetsForThis,
        CorrespondenceFileType.CorrespondenceFile,
      );
      this.correspondenceFile = JSON.parse(
        JSON.stringify(this.oldCorrespondenceFile),
      );
      this.oldAttachments = getFilesByType(
        documnetsForThis,
        CorrespondenceFileType.Attachment,
      );
      this.attachments = JSON.parse(JSON.stringify(this.oldAttachments));
    }
    this.title = this.oldCorr.Guid
      ? "Draft Correspondence "
      : "Create New Correspondence";
    this.ccUsersTableData = this.allUserTableRows.filter((u) => {
      return this.oldCorr.correspondence_cc_user.includes(u.id);
    });
    console.log(this.oldCorr);
    this.corr = JSON.parse(JSON.stringify(this.oldCorr));
  }

  mappingRefUsers(users: CorrespondenceReferenceUser[]): ISelectItem[] {
    const contractCode = this.localContractuServices.currentContract.Code;
    const isPW = contractCode.includes("PW-");
    const adUsers = this.localUserService.getItems();
    return users
      .filter((u) => {
        return !isPW || u.Organization === "AHJV" || u.Organization === "DDC";
      })
      .map((item: CorrespondenceReferenceUser) => {
        let name = item.Name;
        if (!item.UserId || item.UserId === "") {
          name = `${item.Name} (Offline)`;
        }
        else if(adUsers.find((u)=>{return u.id===item.UserId})?.accountEnabled !== true)
        {
          name = `${item.Name} (Offline)`;
        }
        return {
          name: `${name}, ${item.Title ?? "-"}, ${item.Organization ?? "-"}`,
          id: item.Guid,
          checked: false,
        };
      });
  }

  // eslint-disable-next-line class-methods-use-this
  get corrFileType(): typeof CorrespondenceFileType {
    return CorrespondenceFileType;
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldCorr);
    const current = JSON.stringify(this.corr);
    const oldCorrespondenceFile = JSON.stringify(this.oldCorrespondenceFile);
    const currentCorrespondenceFile = JSON.stringify(this.correspondenceFile);
    const oldAttachments = JSON.stringify(this.oldAttachments);
    const attachments = JSON.stringify(this.attachments);

    return (
      old !== current ||
      oldCorrespondenceFile !== currentCorrespondenceFile ||
      oldAttachments !== attachments
    );
  }

  removeUserClick(row: RowItem): void {
    this.ccUsersTableData = this.ccUsersTableData.filter(
      (reviewer) => reviewer.id !== row.id,
    );
    this.corr.correspondence_cc_user = this.ccUsersTableData.map((u) => {
      return u.id;
    });
    this.enableSaveDraft = this.hasChanges();
  }

  openWindowClick(): void {
    this.activeSave = false;

    this.tableRows = this.allUserTableRows
      .filter((user) => {
        return !this.corr.correspondence_cc_user.includes(user.id);
      })
      .map((user) => {
        user.checked = false;
        return user;
      });

    this.showSelectUser = true;
  }

  closeWindow(e?: RowItem[]): void {
    if (this.firstOpen || !e) {
      this.firstOpen = false;
    } else if (this.activeSave) {
      if (e && Array.isArray(e) && e.length > 0) {
        this.ccUsersTableData = this.ccUsersTableData.concat(e);
        this.corr.correspondence_cc_user = this.ccUsersTableData.map((u) => {
          return u.id;
        });

        this.firstOpen = true;
        this.showSelectUser = false;
        this.enableSaveDraft = this.hasChanges();
      } else {
        const modalInstance = this.activeModal.open(NotificationComponent);
        modalInstance.instance.theme = "light";
        modalInstance.instance.title = "Notification";
        modalInstance.instance.body = "Are you sure you would like to cancel?";

        modalInstance.result.then(async (result) => {
          this.firstOpen = true;
          if (result === 1) {
            this.showSelectUser = false;
          }
        });
      }
    } else {
      this.firstOpen = true;
      this.showSelectUser = false;
    }
  }

  IsInputValid(): boolean {
    const inValid = Object.values(this.corr.correspondence_create).find((v) => {
      return isString(v) && v !== null && v !== undefined && !validateInput(v);
    });
    return inValid === undefined;
  }

  IsValid(): boolean {
    if (
      !this.corr.correspondence_create.From ||
      this.corr.correspondence_create.From.trim() === "" ||
      !this.corr.correspondence_create.To ||
      this.corr.correspondence_create.To.trim() === "" ||
      !this.corr.correspondence_create.LetterDate ||
      this.corr.correspondence_create.LetterDate.trim() === "" ||
      !this.corr.correspondence_create.Reference ||
      this.corr.correspondence_create.Reference.trim() === "" ||
      this.corr.correspondence_create.Reference.trim().length >
        this.referenceMaxLength ||
      !this.corr.correspondence_create.Subject ||
      this.corr.correspondence_create.Subject.trim() === "" ||
      this.corr.correspondence_create.Subject.trim().length >
        this.subjectMaxLength ||
      (this.corr.correspondence_create?.Note &&
        this.corr.correspondence_create.Note.trim().length >
          this.notesMaxLength) ||
      this.correspondenceFile.length === 0 ||
      !this.isFileNameValid()
    ) {
      return false;
    }
    return true;
  }

  submit(): void {
    this.attemptToIssue = true;

    if (!this.IsInputValid()) {
      return;
    }

    const validation = this.IsValid();

    if (!validation) {
      return;
    }

    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Submit Correspondence?";
      modalInstance.instance.body =
        "Once it's submitted, you will no longer be able to edit.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.corr.correspondence_create.IsDraft = false;
          this.update();
        }
      });
    }
  }

  saveDraft(): void {
    if (!this.hasChanges()) return;

    if (!this.IsInputValid()) {
      return;
    }

    if (!this.isFileNameValid()) return;

    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Save as Draft?";
      modalInstance.instance.body = "Your Correspondence will save as draft.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.corr.correspondence_create.IsDraft = true;
          this.update();
        }
      });
    }
  }

  update(): void {
    this.loadingService.start();

    console.log(this.corr);

    this.correspondenceItemService.corrUpdate(this.corr).subscribe(
      async (r) => {
        await this.updateFiles();
        this.refreshEntity();
        this.loadingService.stop();
        this.router.navigateByUrl(`${this.contractId}/list`);
      },
      (error: HttpErrorResponse) => {
        this.refreshEntity();
        this.loadingService.stop();
        errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  refreshEntity(): void {
    this.oldCorr = this.corr;
    this.oldCorrespondenceFile = this.correspondenceFile;
    this.oldAttachments = this.attachments;
  }

  async updateFiles(): Promise<void> {
    const allCurrentFiles = [...this.correspondenceFile, ...this.attachments];
    const allOldFiles = [
      ...this.oldCorrespondenceFile.map((f) => f.Guid),
      ...this.oldAttachments.map((f) => f.Guid),
    ];

    const filesToAdd = allCurrentFiles.filter((f) => {
      return !allOldFiles.includes(f.Guid) && f.Status === UploadStatus.LOADED;
    });

    const fileIds = allCurrentFiles.map((f) => {
      return f.Guid;
    });

    const filesToRemove = allOldFiles.filter((f) => {
      return !fileIds.includes(f);
    });

    const removeIds = [...new Set([...filesToRemove, ...this.removedFileIds])];
    const addIds = filesToAdd.map((f) => {
      return f.Guid;
    });
    console.log("service: filesToAdd", filesToAdd);
    console.log("service: fileIds", fileIds);
    console.log("service: filesToRemove", filesToRemove);
    console.log("service: removeIds", removeIds);

    if (addIds.length) {
      await this.correspondenceDocumentsService
        .setCorrespondenceDocumentAsSuccessful(addIds)
        .subscribe();
    }

    if (removeIds.length) {
      await this.correspondenceDocumentsService
        .removeCorrespondenceDocument(removeIds)
        .subscribe();
    }

    console.log("finished");

    return null;
  }

  getSubjectLength(): number {
    return this.corr.correspondence_create?.Subject
      ? this.corr.correspondence_create?.Subject.length
      : 0;
  }

  getReferenceLength(): number {
    return this.corr.correspondence_create?.Reference
      ? this.corr.correspondence_create?.Reference.length
      : 0;
  }

  getNotesLength(): number {
    return this.corr.correspondence_create?.Note
      ? this.corr.correspondence_create?.Note.length
      : 0;
  }

  setFromUser(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.corr.correspondence_create.From = e.id;
      this.enableSaveDraft = this.hasChanges();
    }
  }

  setToUser(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.corr.correspondence_create.To = e.id;
      this.enableSaveDraft = this.hasChanges();
    }
  }

  setLetterDate(e: Date | string): void {
    this.LetterDate = new Date(e);
    this.corr.correspondence_create.LetterDate = this.LetterDate.toISOString();
    this.enableSaveDraft = this.hasChanges();
  }

  setRefernce(e: string): void {
    this.corr.correspondence_create.Reference = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setSubject(e: string): void {
    this.corr.correspondence_create.Subject = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setOrgnizationReferenceNumber(e: string): void {
    this.corr.correspondence_create.OrganizationReferenceNumber = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setNote(e: string): void {
    this.corr.correspondence_create.Note = e;
    this.enableSaveDraft = this.hasChanges();
  }

  // eslint-disable-next-line class-methods-use-this
  importFile(e: FileModel[], type: string): void {
    if (!_.isEmpty(e)) {
      // console.log(type);
      e.forEach(async (item) => {
        if (item.Status === UploadStatus.UPLOADING && item.Percentage === 0) {
          const fileUploaded = new CorrDocumentUpload(
            item.Name,
            this.currentUser,
            type,
            CorrespondenceStatus.Draft,
            this.corr.Guid ?? this.corr.tempId,
          );

          await this.correspondenceDocumentsService
            .createCorrespondenceDocument(fileUploaded)
            .toPromise()
            .then((r: ICorrDocumentUploadReturn) => {
              item.Guid = r.Guid;
              this.updateFileList(item, type);
              this.enableSaveDraft = this.hasChanges();
              if (environment.fileService === "azure") {
                AzureBlobService.uploadFile(r.URL, item);
              } else {
                this.fileManagementService.uploadFileToS3(r.URL, item);
              }
            });
        } else if (
          item.Status === UploadStatus.FAILED ||
          item.Status === UploadStatus.CANCELED
        ) {
          this.deleteFile(item, type);
        }
      });
    }
  }

  deleteFile(e: FileModel, type: string): void {
    this.updateFileList(e, type, true);
    this.enableSaveDraft = this.hasChanges();
  }

  back(): void {
    if (this.hasChanges()) {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Cancel editing?";
      modalInstance.instance.body = "Your changes will not be saved.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.refreshEntity();
          this.router.navigateByUrl(`${this.contractId}/list`);
        }
      });
    } else {
      this.router.navigateByUrl(`${this.contractId}/list`);
    }
  }

  updateFileList(item: FileModel, type: string, isRemove = false): void {
    let index = -1;

    switch (type) {
      case CorrespondenceFileType.CorrespondenceFile:
        index = this.correspondenceFile.findIndex((file) => {
          return file.Name === item.Name;
        });
        if (isRemove) {
          if (index !== -1) {
            this.correspondenceFile.splice(index, 1);
          }
        } else if (index === -1) {
          this.correspondenceFile.push(item);
          this.correspondenceFile = this.correspondenceFile.slice();
        }

        break;
      case CorrespondenceFileType.Attachment:
        index = this.attachments.findIndex((file) => {
          return file.Name === item.Name;
        });
        if (isRemove) {
          if (index !== -1) {
            this.attachments.splice(index, 1);
          }
        } else if (index === -1) {
          this.attachments.push(item);
          this.attachments = this.attachments.slice();
        }
        break;
      default:
        break;
    }
    if (isRemove) {
      this.removedFileIds.push(item.Guid);
    }
    this.isFileNameValid();
  }

  isFileNameValid(): boolean {
    this.correspondenceFileNameValid = true;
    this.correspondenceFile.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.correspondenceFileNameValid = false;
      }
    });
    this.attachmentsNameValid = true;
    this.attachments.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.attachmentsNameValid = false;
      }
    });
    return this.correspondenceFileNameValid && this.attachmentsNameValid;
  }

  hasFilesUploading(): boolean {
    return (
      this.correspondenceFile.some(
        (item) => item.Status === UploadStatus.UPLOADING,
      ) ||
      this.attachments.some((item) => item.Status === UploadStatus.UPLOADING)
    );
  }

  showFileProgressNotification(): void {
    const modalInstance = this.activeModal.open(NotificationComponent);

    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "File upload in progress";
    modalInstance.instance.body =
      "Please wait until all your files have been uploaded";
    modalInstance.instance.type = NotificationType.Information;
    // modalInstance.result.then((result) => {});
  }
}
