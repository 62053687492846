<div class="responseBox" *ngIf="item">
  <div class="responseHeader">
    <span>{{ item.Title }}</span>
    <div
      class="responseHeaderCollapse"
      (click)="item.Collapse = !item.Collapse"
    >
      <i
        [ngClass]="
          item.Collapse
            ? 'icon-action_arrow_down'
            : 'icon-action_arrow_up'
        "
        class="icon icon-blue icon-lg"
      ></i>
    </div>
  </div>
  <div class="responseContent" *ngIf="!item.Collapse">
    <div class="responseBody">
      <div class="responseBodySubTitle">Note for Record</div>
      <div class="responseBodySubContent">{{item.Note}}</div>
    </div>
  </div>
</div>