import { IPDocument } from "../api-generated";

export default class SDocument implements IPDocument {
  public FileName: string;

  public UserId: string;

  public Guid?: string;

  public DateCreated?: string;

  constructor(name: string, userId: string) {
    this.FileName = name;
    this.UserId = userId;
  }
}
