/* eslint-disable import/no-extraneous-dependencies */
import { FileModel } from "@aecom/core";
import { Component, Input, OnInit } from "@angular/core";
import { ICorrDocumentDownload, ICorrListItem } from "@api";
import CorrespondenceFileType from "@models/CorrespondenceFileType";
import CorrespondenceStatus from "@models/CorrespondenceStatus";
import { getDate, getFilesByType } from "@shared/utils";

@Component({
  selector: "corrDetail",
  templateUrl: "./corrDetail.component.html",
  styleUrls: ["./corrDetail.component.scss"],
})
export default class CorrDetailComponent implements OnInit {
  @Input() corr: ICorrListItem;

  @Input() documents: ICorrDocumentDownload[];

  @Input() isDBAdmin = false;

  maxCharacters = 30;

  orignator: string;

  recipent: string;

  reference: string;

  subject: string;

  correspondenceFile: FileModel[] = [];

  attachments: FileModel[] = [];

  letterDate: string;

  receivedDate: string;

  dueDate: string;

  organizationReferenceNumber: string;

  note: string;

  isLoaded = false;

  static getFileIcon(type: string): string {
    return type.includes("image")
      ? " icon icon-image_doc"
      : " icon icon-blank_doc";
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  ngOnInit(): void {
    const none = "N/A";
    if(this.corr.correspondence_create?.correspondence_reference_user_correspondence_create_FromTocorrespondence_reference_user)
    {
      const orignator = this.corr.correspondence_create.correspondence_reference_user_correspondence_create_FromTocorrespondence_reference_user
      this.orignator= `${orignator.Name ?? "-"}, ${orignator.Title ?? "-"}, ${orignator.Organization ?? "-"}, ${orignator.Email ?? "-"}`;
    }
    else{
      this.orignator= none;
    }
    if(this.corr.correspondence_create?.correspondence_reference_user_correspondence_create_ToTocorrespondence_reference_user)
    {
      const recipent = this.corr.correspondence_create.correspondence_reference_user_correspondence_create_ToTocorrespondence_reference_user
      this.recipent= `${recipent.Name ?? "-"}, ${recipent.Title ?? "-"}, ${recipent.Organization ?? "-"}, ${recipent.Email ?? "-"}`;
    }
    else{
      this.recipent= none;
    }

    this.reference= this.corr.correspondence_create?.Reference ?? none;
    this.subject= this.corr.correspondence_create.Subject ?? none;
    
    this.letterDate=this.corr.correspondence_create?.LetterDate ? getDate(this.corr.correspondence_create.LetterDate) : none;
    this.receivedDate= this.corr.ReceivedDate ? getDate(this.corr.ReceivedDate) : none;
    this.dueDate= this.corr.DueDate ? getDate(this.corr.DueDate) : none;

    this.organizationReferenceNumber= this.corr.correspondence_create.OrganizationReferenceNumber ?? none;
    this.note= this.corr.correspondence_create.Note ?? none;
    const documentsForThis = this.documents.filter((item)=>{return item.Status === CorrespondenceStatus.Draft});
    this.correspondenceFile = getFilesByType(documentsForThis, CorrespondenceFileType.CorrespondenceFile);
    this.attachments = getFilesByType(documentsForThis, CorrespondenceFileType.Attachment)
    this.isLoaded = true;
  }
}
