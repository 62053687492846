import { CorrespondenceIssued, ICorrIssuedInput } from "@api";

import TimeStampWithDraft from "./timeStampWithDraft";

export default class CorrIssueBase
  extends TimeStampWithDraft
  implements ICorrIssuedInput
{
  public Guid?: string;

  public RequireResponse?: boolean | null;

  public ReceivedToday?: boolean | null;

  public IsConfidential?: boolean | null;

  public CorrespondenceTypeId?: string | null;

  public Topics?: string | null;

  public DueDate?: string | null;

  public ReceivedDate?: string | null;

  public ManagerId?: string | null;

  public Note?: string | null;

  constructor(item?: CorrespondenceIssued) {
    super();
    this.RequireResponse = item?.RequireResponse ?? false;
    this.ReceivedToday = item?.ReceivedToday ?? true;
    this.IsConfidential = item?.IsConfidential ?? true;
    this.CorrespondenceTypeId = item?.CorrespondenceTypeId;
    this.Topics = item?.Topics ?? "";
    this.DueDate = item?.DueDate;
    this.ReceivedDate = item?.ReceivedDate;
    this.ManagerId = item?.ManagerId;
    this.Note = item?.Note;
  }
}
