/* eslint-disable no-nested-ternary */
import CorrespondenceRole from "@models/CorrespondenceRole";
import CorrespondenceStatus from "@models/CorrespondenceStatus";
import { CorrespondenceType, ICorrListItem } from "@api";
import {
  getDate,
  getDateFormatted_MMDDYYYY,
  getIcon,
  getLastestIssuedItem,
  getNavigationUrl,
  getStatusText,
  isFinalStep,
} from "@shared/utils";
import LocalContractUserService from "@services/local-contractUser.service";
import { correspondenceAppId } from "@shared/staticValue";
import LoginUser from "./loginUser";

interface ReferenceUser {
  name: string;
  org: string;
}

export default class CorrListRowItem {
  public Guid: string;

  public CorrNumber: string;

  public Subject: string;

  public From: string;

  public To: string;

  public FromOrg: string;

  public ToOrg: string;

  public OrganizationRef: string;

  public ReferenceNumber: string;

  public LetterDate: string;

  public ReceivedDate: string;

  public DueDate: string;

  public LetterDateLog: string | Date;

  public ReceivedDateLog: string | Date;

  public DueDateLog: string | Date;

  public LetterDateSort: string;

  public ReceivedDateSort: string;

  public DueDateSort: string;

  public KeyWords: string[];

  public Status: string;

  public StatusText: string;

  public StatusIcon: string;

  public CorrespondenceType: string | undefined;

  public BtnText: string;

  public BtnClass: string;

  public BtnUrl: string;

  public IsHidden: boolean;

  public ViewOnly: boolean;

  public HideBtn: boolean;

  public initSort: number;

  constructor(
    item: ICorrListItem,
    userRole: string,
    user: LoginUser,
    corrTypes: CorrespondenceType[],
    localContractUserService: LocalContractUserService,
  ) {
    this.Guid = item.Guid;
    this.CorrNumber = item.CorrespondenceNumber;
    this.Subject = item.correspondence_create?.Subject;
    const fromUser =
      item.correspondence_create
        ?.correspondence_reference_user_correspondence_create_FromTocorrespondence_reference_user;
    let fromUserId = "";
    if (fromUser) {
      this.From = fromUser.Name;
      this.FromOrg = fromUser.Organization;
      fromUserId = fromUser.UserId ?? "";
    }
    const toUser =
      item.correspondence_create
        ?.correspondence_reference_user_correspondence_create_ToTocorrespondence_reference_user;
    let toUserId = "";
    if (toUser) {
      this.To = toUser.Name;
      this.ToOrg = toUser.Organization;
      toUserId = toUser.UserId ?? "";
    }
    this.ReferenceNumber = item.correspondence_create?.Reference;
    this.OrganizationRef =
      item.correspondence_create?.OrganizationReferenceNumber ?? "";
    this.LetterDate = getDate(item.correspondence_create?.LetterDate);
    this.ReceivedDate = getDate(item.ReceivedDate);
    this.DueDate = getDate(item.DueDate);
    this.LetterDateLog = CorrListRowItem.getFormattedDate(
      item.correspondence_create?.LetterDate,
    );
    this.ReceivedDateLog = CorrListRowItem.getFormattedDate(item.ReceivedDate);
    this.DueDateLog = CorrListRowItem.getFormattedDate(item.DueDate);
    this.LetterDateSort = item.LetterDate ?? "-";
    this.ReceivedDateSort = item.ReceivedDate ?? "-";
    this.DueDateSort = item.DueDate ?? "-";
    const lastIssued = getLastestIssuedItem(item);
    this.KeyWords = CorrListRowItem.sortKeyWords(lastIssued?.Topics);
    this.Status = item.Status;
    this.StatusText = getStatusText(
      item.Status,
      userRole === CorrespondenceRole.DBAdmin,
    );
    this.StatusIcon = getIcon(
      item.Status,
      userRole === CorrespondenceRole.DBAdmin,
    );
    const typeId = lastIssued?.CorrespondenceTypeId;
    if (typeId) {
      this.CorrespondenceType = corrTypes.find((ct) => {
        return ct.Guid === typeId;
      })?.Title;
    }
    this.BtnText = CorrListRowItem.getBtnText(item);
    const isRepresent =
      userRole.includes(CorrespondenceRole.DocControl) &&
      item.correspondence_issued !== null;
    this.BtnClass = CorrListRowItem.getBtnClass(item.ViewOnly, isRepresent);
    this.BtnUrl = getNavigationUrl(item);
    this.IsHidden = false;
    this.ViewOnly = item.ViewOnly;
    const IsConfidential = !lastIssued || lastIssued.IsConfidential;
    const hasRole =
      user.Email === fromUser?.Email ||
      user.Email === toUser?.Email ||
      (item.correspondence_cc_user &&
        item.correspondence_cc_user.filter((cc)=>{return !cc.DeletedBy}).map((cc) => cc.UserId).includes(user.Id));
    const isDB = userRole === CorrespondenceRole.DBAdmin;
    const originatorId = item.correspondence_create?.UpdatedBy ?? "";
    const fromOrToDB = [originatorId, fromUserId, toUserId].some((id) => {
      const role = localContractUserService
        .getUserById(id)
        ?.contract_user_application_role?.find((a) => {
          return a.ApplicationId === correspondenceAppId;
        }).application_role?.Role;
      return role === CorrespondenceRole.DBAdmin;
    });
    if (isDB && fromOrToDB) {
      this.HideBtn = false;
    } else {
      this.HideBtn =
        this.ViewOnly === true && IsConfidential !== false && !hasRole;
    }

    this.initSort = CorrListRowItem.getInitSort(this.ViewOnly, isRepresent);
  }

  static getBtnText(item: ICorrListItem): string {
    const view = "View";
    const review = "Review";
    const edit = "Edit";
    if (item.ViewOnly === true || isFinalStep(item.Status)) {
      return view;
    }
    if (item.Status === CorrespondenceStatus.Draft) {
      return edit;
    }
    return review;
  }

  static getBtnClass(viewonly: boolean, isRepresent: boolean): string {
    return viewonly
      ? "btn outline lg blue"
      : isRepresent
      ? "btn solid lg altBottonColor"
      : "btn solid lg blue";
  }

  static getInitSort(viewonly: boolean, isRepresent: boolean): number {
    return viewonly ? 0 : isRepresent ? 1 : 2;
  }

  // static sortKeyWords(values?: string): string | undefined {
  //   if (values) {
  //     return values.trim().split(",").sort().join(", ");
  //   }
  //   return undefined;
  // }
  static sortKeyWords(values?: string): string[] {
    if (values) {
      return values.trim().split(",").sort();
    }
    return [];
  }

  static getFormattedDate(date: string): string | Date {
    if (!date) return "-";
    return new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));
  }
}
