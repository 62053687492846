import { CorrespondenceIssued } from "@api";
import { correspondenceAppId, NA } from "@shared/staticValue";
import { getDateFormatted_MMDDYYYY } from "@shared/utils";
import IContractUserWithUserInfo from "./IContractUserWithUserInfo";

export default class ResponseDetailItem {
  public Title: string;

  public RequestResponse: boolean;

  public DueDate: string;

  public Note: string;

  public Collapse: boolean;

  constructor(item: CorrespondenceIssued, users: IContractUserWithUserInfo[]) {
    const contractUser = users.find((u) => {
      return u.UserId === item.UpdatedBy;
    });
    const role = contractUser.contract_user_application_role.find((r) => {
      return r.ApplicationId === correspondenceAppId;
    })?.application_role?.Role;
    const name = contractUser.UserInfo?.displayName;
    this.Title = role && name ? `${name}  |  ${role}` : NA;
    this.RequestResponse = item.RequireResponse;
    this.DueDate = item.DueDate ? getDateFormatted_MMDDYYYY(item.DueDate) : NA;
    this.Note = item.Note ?? NA;
    this.Collapse = false;
  }
}
