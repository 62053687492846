<div class="maincontainer">
  <div class="w-100 headerSection">
    <h1 class="headerTitle">{{ title }}</h1>

    <div class="btn bg-blue ml-auto headerCloseBtn" id="closeButton" (click)="cancel()">
      <i class="icon icon-black icon-xl icon-action_remove"></i>
    </div>
  </div>

  <div class="contentcontainer">
    <section class="w-100 formSection">
      <div class="row w-100 mx-0 pt-3">
        <aecom-table
          class="selectUserTable"
          [ngValue]="tableData"
          (ngValueChange)="tableRows = $event"
          [theme]="'light'"
          innerClass="list"
          [canSearch]="true"
          [columnFilters]="columnFilters"
        >
          <thead>
            <tr>
              <th class="checkbox">
                <aecom-column-selector (ngValueChange)="checkBoxChange()"></aecom-column-selector>
              </th>

              <ng-container *ngFor="let hCol of tableHeader">
                <th *ngIf="hCol.Type !== columnType.Hide" class="sort selectUser_th" id="{{ hCol.Name }}">
                  {{ hCol.Header }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let bRow of tableRows; let i = index; trackBy: test" class="selectUser_tr_hover">
              <td class="checkbox">
                <aecom-checkbox [(ngValue)]="bRow.checked" (ngValueChange)="checkBoxChange(i)"></aecom-checkbox>
              </td>
              <ng-container *ngFor="let hCol of tableHeader">
                <td *ngIf="hCol.Type !== columnType.Hide">
                  <span class="selectUser_td_Item">{{ bRow[hCol.Name] }}</span>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </aecom-table>
      </div>
    </section>

    <section class="buttonSection">
      <div class="d-flex row mx-0 pt-2" style="line-height: 48px">
        <div class="ml-auto" style="display: flex; align-items: center">
          <aecom-button
            id="cancelButton"
            innerClass="btn orange btn-icon outline ml-auto"
            class="px-2"
            [theme]="'light'"
            (click)="cancel()"
          >
            Cancel
          </aecom-button>

          <aecom-button
            id="saveButton"
            [theme]="'light'"
            [disabled]="!activeSave"
            [innerClass]="activeSave ? 'btn blue btn-icon solid' : 'btn bg-grey-d btn-icon solid disabled'"
            class="px-2"
            (click)="submit()"
          >
            <span [ngStyle]="{ color: activeSave ? 'black' : '#8c8279' }">Assign</span>
          </aecom-button>
        </div>
      </div>
    </section>
  </div>
</div>
