<ng-container>
  <corrHeader [corr]="entity" [IsDbAdmin]="isDBAdmin"></corrHeader>
  <aecom-tabs class="cusTab" [defaultTab]="defaultTab">
    <aecom-tab title="details">
      <div style="overflow-y: auto; overflow-x: hidden">
        <corrDetail
          [corr]="entity"
          [isDBAdmin]="isDBAdmin"
          [documents]="docs"
        ></corrDetail>
      </div>
    </aecom-tab>
    <aecom-tab title="processing" *ngIf="!isDBAdmin">
      <corrProcessing [corr]="entity"></corrProcessing>
    </aecom-tab>
  </aecom-tabs>
</ng-container>