// eslint-disable-next-line import/no-extraneous-dependencies
import { Component, Input, OnInit } from "@angular/core";
import { ICorrListItem } from "@api/model/iCorrListItem";
import { getIcon, getStatusText } from "@shared/utils";

@Component({
  selector: "corrHeader",
  templateUrl: "./corrHeader.component.html",
  styleUrls: ["./corrHeader.component.scss"],
})
export default class CorrHeaderComponent implements OnInit {
  @Input() corr: ICorrListItem;

  @Input() IsDbAdmin = false;

  number: string;

  icon: string;

  status: string;

  ngOnInit(): void {
    this.number = this.corr.CorrespondenceNumber;
    this.status = getStatusText(
      this.corr.Status,
      this.IsDbAdmin
    );
    this.icon = getIcon(
      this.corr.Status,
      this.IsDbAdmin
    );
  }
}