import { ICorrItemBase } from "@api";
import TimeStamp from "./timeStamp";

export default class CorrespondenceUpdateBase extends TimeStamp {
  public Guid?: string;

  public ContractId: string;

  public Status?: string;

  public BallInCourt?: string;

  public CorrespondenceNumber?: string;

  constructor(contractId: string, item?: ICorrItemBase) {
    super(item);
    this.Guid = item?.Guid;
    this.ContractId = item?.ContractId ?? contractId;
    this.Status = item?.Status;
    this.BallInCourt = item?.BallInCourt;
    this.CorrespondenceNumber = item?.CorrespondenceNumber;
  }
}
