import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import AuthService from "./auth.service";
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export default class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return from(this.authService. waitForToken().then(async () => {
      // if(this.authService.isTokenExpired())
      // {
      //   await this.authService.refreshToken();
      // }
      const refreshToken = this.getRefreshToken();
      return req.clone({
        setHeaders: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json",
          Authorization: `Bearer ${this.authService.token}`,
          refresh_token: refreshToken,
        },
      });
    })).pipe(mergeMap((newReq) => {
      return next.handle(newReq) 
    }));
  }

  private getRefreshToken(): string | null {
    let tokenkey: string;
    for (let i = 0; i < localStorage.length; i++){
      if (localStorage.key(i).includes('refreshtoken')) {
        tokenkey = localStorage.key(i);
        break;
      }
    }
    const refreshToken = localStorage.getItem(tokenkey);
    if(refreshToken)
    {
      return JSON.parse(refreshToken).secret;
    }
    return null;
  }
}
