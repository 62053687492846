<div *ngIf="corr" class="maincontainer light-theme">
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel [entity]="entity" [docs]="docs" [defaultTab]="defaultTab"></leftPanel>
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button
                data-cy="back"
                innerClass="btn lg outline orange"
                (click)="back()"
              >
                back
              </aecom-button>

              <aecom-button
                [theme]="'light'"
                [disabled]="!enableSaveDraft ? true : null"
                innerClass="btn lg solid green"
                (click)="saveDraft()"
              >
                save draft
              </aecom-button>

              <aecom-button
                data-cy="submit"
                innerClass="btn lg solid blue"
                (click)="submit()"
              >
                Submit
              </aecom-button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <aecom-form columns="6">
            <aecom-form-group
              columnSpan="6"
              columns="2"
              title="Response Required"
              style="margin-bottom: 40px"
              class="matchToTab"
            >
              <aecom-form-item
                data-cy="wantToResponse"
                label="Do you want to respond to this Correspondence?"
                columnSpan="1"
              >
                <div class="button-wrapper d-flex" style="width: 90%;">
                  <aecom-button
                    id="wantToResponseYes"
                    innerClass="btn solid btn-tab {{
                      corr.correspondence_response.WantToResponse
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setWantToResponse(true)"
                  >
                    Yes
                  </aecom-button>

                  <aecom-button
                    id="wantToResponseNo"
                    innerClass="btn solid btn-tab {{
                      !corr.correspondence_response.WantToResponse
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setWantToResponse(false)"
                  >
                    No
                  </aecom-button>
                </div>
              </aecom-form-item>
              <aecom-form-item
                *ngIf="corr.correspondence_response.WantToResponse"
                label="Correspondence Templates"
                columnSpan="1"
              >
                <div style="display:flex;">
                  <div><a class="templateLink" href="https://aecominnovationstudio.sharepoint.com/:w:/r/sites/bbjprogram/_layouts/15/Doc.aspx?sourcedoc=%7B4C0255C6-2E4F-4B8C-8DC5-284A91246E6E%7D&file=BBJ-PW-ALL-AHJV%20Letterhead.dotx&action=default&mobileredirect=true" target="_blank">AHJV Letterhead</a></div>
                  <div style="margin-left: 16px;"><a class="templateLink" href="https://aecominnovationstudio.sharepoint.com/:w:/r/sites/bbjprogram/DDC_PW_ALL/Program%20Administration/Templates/BBJ-PW-ALL-DDC_Letterhead.doc?d=w57ca7352f9bf4c67b8db9ac217988982&csf=1&web=1&e=DCEnzI" target="_blank">DDC Letterhead</a></div>
                </div>
              </aecom-form-item>
              <aecom-form-item
                columnSpan="2"
                [label]="'Note (Optional)'"
                [property]="
                (corr.correspondence_response.Note ? corr.correspondence_response.Note.length : 0) + '/1500 characters'
                "
              >
                <aecom-textbox
                  data-cy="rejectNote"
                  [theme]="'light'"
                  [(ngValue)]="corr.correspondence_response.Note"
                  rows="6"
                  [maxlength]="1500"
                  [isMultiLine]="true"
                ></aecom-textbox>
              </aecom-form-item>
            </aecom-form-group>

            <aecom-form-group
              *ngIf="corr.correspondence_response.WantToResponse"
              columnSpan="6"
              columns="2"
              title="Response"
              style="margin-bottom: 40px"
            >
              <aecom-form-item label="Letter Date" columnSpan="1">
                <aecom-date
                  id="letterDate"
                  data-cy="letterDate"
                  [notEmpty]="true"
                  [theme]="'light'"
                  [ngValue]="letterDate"
                  (change)="setDate($event)"
                  [validate]="canIssue && !letterDate"
                >
                </aecom-date>
              </aecom-form-item>
              <aecom-form-item label="Reference ID (Optional)" columnSpan="1">
                <aecom-textbox
                  data-cy="referenceId"
                  [theme]="'light'"
                  [(ngValue)]="corr.correspondence_response.ReferenceId"
                  (ngValueChange)="setReferenceId($event)"
                ></aecom-textbox>
              </aecom-form-item>
              <aecom-form-group title="Correspondence File" columnSpan="1" columns="1" style="margin-top:40px;">
                <aecom-form-item>
                  <aecom-upload
                    data-cy="correspondenceFile"
                    [isNewStyle]="true"
                    [multiFiles]="true"
                    [canDelete]="true"
                    [data]="correspondenceFile"
                    [theme]="'light'"
                    (ngValueChange)="
                      importFile($event, correspondenceFileType.CorrespondenceFile)
                    "
                    (ngDeleteChange)="
                      deleteFile($event, correspondenceFileType.CorrespondenceFile)
                    "
                  ></aecom-upload>
                  <div
                    class="error-message"
                    *ngIf="correspondenceFile?.length === 0 && canIssue"
                  >
                    Required Field
                  </div>
                  <div class="error-message" *ngIf="!correspondenceFileNameValid">
                    File Name cannot include special characters other than '-',
                    '_', '.', '@'
                  </div>
                </aecom-form-item>
              </aecom-form-group>
              <aecom-form-group title="Attachments (Optional)" columnSpan="1" columns="1" style="margin-top:40px;">
                <aecom-form-item>
                  <aecom-upload
                    data-cy="attachments"
                    [isNewStyle]="true"
                    [multiFiles]="true"
                    [canDelete]="true"
                    [data]="attachments"
                    [theme]="'light'"
                    (ngValueChange)="
                      importFile($event, correspondenceFileType.Attachment)
                    "
                    (ngDeleteChange)="
                      deleteFile($event, correspondenceFileType.Attachment)
                    "
                  ></aecom-upload>
                  <div class="error-message" *ngIf="!attachmentsNameValid">
                    File Name cannot include special characters other than '-',
                    '_', '.', '@'
                  </div>
                </aecom-form-item>
              </aecom-form-group>
              <aecom-form-item
                columnSpan="2"
                columns="1"
                class="formItemNoMargin"
              >
                <aecom-form-group
                  columnSpan="1"
                  columns="1"
                  title="CC (Optional)"
                  style="position: relative"
                >
                  <div style="width: 100%; height: 250px" class="noTableFilter">
                    <aecom-table
                      [ngValue]="ccUsersTableData"
                      (ngValueChange)="ccUsersTableRows = $event"
                      [theme]="'light'"
                      innerClass="list"
                    >
                      <tbody>
                        <tr
                          *ngFor="let bRow of ccUsersTableRows"
                          class="assignUser_tr_hover"
                        >
                          <ng-container *ngFor="let hCol of tableHeader">
                            <td *ngIf="hCol.Type !== columnType.Hide">
                              <span class="assignUser_td_Item">
                                {{ bRow[hCol.Name] }}
                              </span>
                            </td>
                          </ng-container>

                          <td>
                            <a
                              class="btn"
                              (click)="removeUserClick(bRow)"
                            >
                              <i
                                class="
                                  icon
                                  icon-social_user_remove
                                  icon-orange icon-lg
                                "
                              ></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </aecom-table>

                    <div
                      style="
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                      "
                      *ngIf="ccUsersTableData?.length === 0"
                    >
                      <div class="noItem-body">
                        <div>
                          <div
                            class="
                              noItem-icon-row
                              icon
                              icon-social_user_team_big
                              icon-grey icon-xl
                            "
                          ></div>
                        </div>

                        <div class="noItem-title-row">None</div>
                      </div>
                    </div>
                  </div>
                  <div
                    style="
                      position: absolute;
                      top: 7px;
                      right: 0;
                      cursor: pointer;
                    "
                  >
                    <div
                      data-cy="addCCUser"
                      class="formGroupManu"
                      (click)="openWindowClick()"
                    >
                      + Add Users
                    </div>
                  </div>
                </aecom-form-group>
              </aecom-form-item>
            </aecom-form-group>
          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showSelectUser" class="up-contentcontainer">
  <div class="selectUserWindow" (clickOutside)="closeWindow()">
    <select-user
      data-cy="selectUser"
      [tableData]="tableRows"
      [title]="'ADD CC'"
      (close)="closeWindow($event)"
      [(firstOpen)]="firstOpen"
      [(activeSave)]="activeSave"
    ></select-user>
  </div>
</div>

