/* eslint-disable import/no-extraneous-dependencies */
import { Component, Input, OnInit } from "@angular/core";
import { ICorrDocumentDownload, ICorrListItem } from "@api";
import CorrespondenceRole from "@models/CorrespondenceRole";
import LocalContractUserService from "@services/local-contractUser.service";

@Component({
  selector: "leftPanel",
  templateUrl: "./leftPanel.component.html",
})
export default class LeftPanelComponent implements OnInit {
  @Input() entity: ICorrListItem;

  @Input() docs: ICorrDocumentDownload[];

  @Input() defaultTab = "details";

  isDBAdmin = true;

  constructor(public localContractUserService: LocalContractUserService){}
  
  ngOnInit(): void {
    const role = this.localContractUserService.currentUserContractRole;
    this.isDBAdmin = role === CorrespondenceRole.DBAdmin;
  }
}
