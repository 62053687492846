<div class="container-fluid" *ngIf="corr">
  <div class="row">
    <div class="col-12">
      <div class="page-header">
        <div class="col-6" style="padding: 0; display: flex">
          <div class="header-title">
            <p>
              <a [routerLink]="['/' + contractId + '/list']"> Correspondence List &gt; </a>
              <span> {{ corr.Guid ? "Draft Correspondence" : "Create Correspondence" }}</span>
            </p>

            <h1>
              <i class="icon icon-lg icon-pencil"></i>

              <span style="text-transform: uppercase">{{ title }}</span>
            </h1>
          </div>
        </div>

        <div class="col-6" style="padding: 0">
          <div class="header-actions">
            <aecom-button data-cy="back" innerClass="btn lg outline orange" (click)="back()"> back </aecom-button>

            <aecom-button
              [theme]="'light'"
              [disabled]="!enableSaveDraft ? true : null"
              innerClass="btn lg solid green"
              (click)="saveDraft()"
            >
              save draft
            </aecom-button>

            <aecom-button data-cy="submit" [theme]="'light'" innerClass="btn lg solid blue" (click)="submit()"> submit </aecom-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <aecom-form columns="12">
        <aecom-form-group
          title="General"
          columnSpan="6"
          columns="2"
          style="display: block; margin-bottom: 10px; min-height: 60%"
        >
          <aecom-form-item [label]="'From - Sender Name'" columnSpan="1">
            <aecom-dropdown
            data-cy="from"
              [theme]="'light'"
              [autocomplete]="true"
              placeholder="Select"
              [data]="fromList"
              [selectedId]="corr.correspondence_create.From"
              [validate]="attemptToIssue && !corr.correspondence_create.From"
              (ngValueChange)="setFromUser($event)"
            ></aecom-dropdown>
          </aecom-form-item>
          <aecom-form-item [label]="'To - Recipient Name'" columnSpan="1">
            <aecom-dropdown
            data-cy="to"
              [theme]="'light'"
              [autocomplete]="true"
              placeholder="Select"
              [data]="toList"
              [selectedId]="corr.correspondence_create.To"
              [validate]="attemptToIssue && !corr.correspondence_create.To"
              (ngValueChange)="setToUser($event)"
            ></aecom-dropdown>
          </aecom-form-item>
          <aecom-form-item [label]="'Letter Date'" columnSpan="1">
            <aecom-date
              data-cy="letterDate"
              [theme]="'light'"
              [ngValue]="LetterDate"
              [notEmpty]="true"
              (change)="setLetterDate($event)"
              [validate]="!LetterDate && attemptToIssue"
            ></aecom-date>

            <div
              class="error-message"
              *ngIf="!LetterDate && attemptToIssue"
            >
              Please choose correct date.
            </div>
          </aecom-form-item>
          <aecom-form-item [label]="'Organization Reference Number (Optional)'" columnSpan="1">
            <aecom-textbox
            data-cy="referenceNumber"
              [theme]="'light'"
              [(ngValue)]="corr.correspondence_create.OrganizationReferenceNumber"
              (ngValueChange)="setOrgnizationReferenceNumber($event)"
            ></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'Reference'" columnSpan="2" [property]="getReferenceLength() + '/' + referenceMaxLength + ' characters'">
            <aecom-textbox
              data-cy="reference"
              rows="3"
              [notEmpty]="true"
              [theme]="'light'"
              [isMultiLine]="true"
              [maxlength]="referenceMaxLength"
              [(ngValue)]="corr.correspondence_create.Reference"
              (ngValueChange)="setRefernce($event)"
              [validate]="attemptToIssue && (!corr.correspondence_create.Reference || corr.correspondence_create.Reference.trim()==='' || getReferenceLength() > referenceMaxLength)"
            ></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'Subject'" columnSpan="2" [property]="getSubjectLength() + '/' + subjectMaxLength + ' characters'">
            <aecom-textbox
              data-cy="subject"
              rows="3"
              [notEmpty]="true"
              [theme]="'light'"
              [isMultiLine]="true"
              [maxlength]="subjectMaxLength"
              [(ngValue)]="corr.correspondence_create.Subject"
              (ngValueChange)="setSubject($event)"
              [validate]="attemptToIssue && (!corr.correspondence_create.Subject || corr.correspondence_create.Subject.trim()==='' || getSubjectLength() > subjectMaxLength)"
            ></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item columnSpan="1" [label]="'Correspondence File'">
            <aecom-upload
              data-cy="correspondenceFile"
              [isNewStyle]="true"
              [multiFiles]="true"
              [canDelete]="true"
              [data]="correspondenceFile"
              [theme]="'light'"
              (ngValueChange)="importFile($event, corrFileType.CorrespondenceFile)"
              (ngDeleteChange)="deleteFile($event, corrFileType.CorrespondenceFile)"
            ></aecom-upload>
            <div class="error-message" *ngIf="correspondenceFile.length === 0 && attemptToIssue">Required Field</div>
            <div class="error-message" *ngIf="!correspondenceFileNameValid">
              File Name cannot include special characters other than '-', '_', '.', '@'
            </div>
          </aecom-form-item>

          <aecom-form-item columnSpan="1" [label]="'Attachments (Optional)'">
            <aecom-upload
              data-cy="attachments"
              [isNewStyle]="true"
              [multiFiles]="true"
              [canDelete]="true"
              [data]="attachments"
              [theme]="'light'"
              (ngValueChange)="importFile($event, corrFileType.Attachment)"
              (ngDeleteChange)="deleteFile($event, corrFileType.Attachment)"
            ></aecom-upload>
            <div class="error-message" *ngIf="!attachmentsNameValid">
              File Name cannot include special characters other than '-', '_', '.', '@'
            </div>
          </aecom-form-item>
        </aecom-form-group>
        <aecom-form-group
          columnSpan="6"
          columns="2"
          title="CC (Optional)"
          style="position: relative"
        >
          <div style="width: 100%; height: 250px; grid-column: span 2;" class="noTableFilter">
            <aecom-table
              [ngValue]="ccUsersTableData"
              (ngValueChange)="ccUsersTableRows = $event"
              [theme]="'light'"
              innerClass="list"
            >
              <tbody>
                <tr
                  *ngFor="let bRow of ccUsersTableRows"
                  class="assignUser_tr_hover"
                >
                  <ng-container *ngFor="let hCol of tableHeader">
                    <td *ngIf="hCol.Type !== columnType.Hide">
                      <span class="assignUser_td_Item">
                        {{ bRow[hCol.Name] }}
                      </span>
                    </td>
                  </ng-container>

                  <td>
                    <a
                      class="btn"
                      (click)="removeUserClick(bRow)"
                    >
                      <i
                        class="
                          icon
                          icon-social_user_remove
                          icon-orange icon-lg
                        "
                      ></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </aecom-table>

            <div
              style="
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
              "
              *ngIf="ccUsersTableData?.length === 0"
            >
              <div class="noItem-body">
                <div>
                  <div
                    class="
                      noItem-icon-row
                      icon
                      icon-social_user_team_big
                      icon-grey icon-xl
                    "
                  ></div>
                </div>

                <div class="noItem-title-row">None</div>
              </div>
            </div>
          </div>
          <div
            style="
              position: absolute;
              top: 5px;
              right: 0;
              cursor: pointer;
            "
          >
            <div
              data-cy="addCCUser"
              class="formGroupManu"
              (click)="openWindowClick()"
            >
              + Add Users
            </div>
          </div>
          <aecom-form-item [label]="'Additional Notes'" columnSpan="6" [property]="getNotesLength() + '/' + notesMaxLength + ' characters'">
            <aecom-textbox
              data-cy="note"
              rows="3"
              [theme]="'light'"
              [isMultiLine]="true"
              [maxlength]="notesMaxLength"
              [(ngValue)]="corr.correspondence_create.Note"
              (ngValueChange)="setNote($event)"
              [validate]="attemptToIssue && getNotesLength() > notesMaxLength"
            ></aecom-textbox>
          </aecom-form-item>
        </aecom-form-group>
        
      </aecom-form>
    </div>
  </div>
</div>

<div *ngIf="showSelectUser" class="up-contentcontainer">
  <div class="selectUserWindow" (clickOutside)="closeWindow()">
    <select-user
      data-cy="selectUser"
      [tableData]="tableRows"
      [title]="'ADD CC'"
      (close)="closeWindow($event)"
      [(firstOpen)]="firstOpen"
      [(activeSave)]="activeSave"
    ></select-user>
  </div>
</div>
