/* eslint-disable import/no-extraneous-dependencies */

import { Component, Input } from "@angular/core";
import ResponseDetailItem from "@models/responseDetailItem";

@Component({
  selector: "responseDetail",
  templateUrl: "./responseDetail.component.html",
  styleUrls: ["./responseDetail.component.scss"],
})
export default class ResponseDetailComponent {
  @Input() item: ResponseDetailItem;
}
