import { FileModel, UploadStatus } from "@aecom/core";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export default class FileManagementService {
  constructor() {}

  async uploadFileToS3(presigndata, file: FileModel) {
    return await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const formData = new FormData();

      Object.keys(presigndata.fields).forEach((key) => {
        formData.append(key, presigndata.fields[key]);
      });

      // Actual file has to be appended last.
      formData.append("file", file.File);

      xhr.upload.addEventListener(
        "progress",
        async function (evt) {
          if (evt.lengthComputable) {
            file.Percentage = evt.loaded / evt.total;
            file.Percentage = Math.floor(file.Percentage * 100);

            if (file.Percentage >= 100) {
              file.Status = UploadStatus.LOADED;
            }

            if (file.Status == UploadStatus.CANCELED) {
              xhr.abort();
            }
          }
        },
        false,
      );

      xhr.onload = function () {
        if (this.status === 204) {
          resolve("");
        } else {
          console.error("Failed to upload file", this.responseText);
          reject(this.responseText);
        }
      };

      xhr.onerror = function (e) {
        file.Status = UploadStatus.FAILED;
      };

      xhr.onabort = function (e) {
        file.Status = UploadStatus.FAILED;
      };

      xhr.ontimeout = function (e) {
        file.Status = UploadStatus.FAILED;
      };

      xhr.open("POST", presigndata.url, true);
      xhr.send(formData);
    });
  }
}
