import { ICorrIssuedStepItem, ICorrListItem } from "@api";
import { getLastestIssuedItem } from "@shared/utils";
import CorrespondenceUpdateBase from "./CorrespondenceUpdateBase";
import CorrIssueBase from "./corrIssueBase";

export default class CorrIssue
  extends CorrespondenceUpdateBase
  implements ICorrIssuedStepItem
{
  public correspondence_issued: CorrIssueBase;

  public correspondence_cc_user?: string[];

  constructor(contractId: string, item?: ICorrListItem) {
    super(contractId, item);

    const lastIssued = getLastestIssuedItem(item);
    this.correspondence_issued = lastIssued
      ? new CorrIssueBase(lastIssued)
      : new CorrIssueBase();

    if (item.correspondence_cc_user) {
      this.correspondence_cc_user = item.correspondence_cc_user.map((user) => {
        return user.UserId;
      });
    } else {
      this.correspondence_cc_user = [];
    }
  }
}
