/* eslint-disable prettier/prettier */
/* eslint-disable prefer-destructuring */
import { Injectable } from "@angular/core";
import { Resolve, Router } from "@angular/router";
import { Observable } from "rxjs";
import { CorrespondenceReferenceUser, CorrespondenceRefrenceUserService } from "@api";

@Injectable({
  providedIn: "root"
})
export default class CreateStepResolver implements Resolve<CorrespondenceReferenceUser[]> {
  constructor(
    public router: Router,
    private correspondenceRefrenceUserService: CorrespondenceRefrenceUserService
  ) {}

  resolve(): Observable<CorrespondenceReferenceUser[]> {
    return this.correspondenceRefrenceUserService.getAllCorrespondenceRefrenceUsers();
  }
}
