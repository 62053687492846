import {
  FileModel,
  ModalContainerService,
  NotificationComponent,
  NotificationType,
  UploadStatus,
} from "@aecom/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CorrespondenceDocumentsService,
  CorrespondenceItemService,
  ICorrDocumentDownload,
  ICorrDocumentUploadReturn,
  ICorrListItem,
} from "@api";
import AuthService from "@auth/auth.service";
import ColumnType from "@models/columnType";
import CorrDocumentUpload from "@models/corrDocumentUpload";
import CorrespondenceFileType from "@models/CorrespondenceFileType";
import CorrespondenceStatus from "@models/CorrespondenceStatus";
import CorrInReview from "@models/corrInReview";
import IContractUserWithUserInfo from "@models/IContractUserWithUserInfo";
import IViewPrepare from "@models/IViewPrepaer";
import AzureBlobService from "@services/azureBlob.service";
import FileManagementService from "@services/fileManagement.service";
import LoadingService from "@services/loading.service";
import LocalContractUserService from "@services/local-contractUser.service";
import tableHeader from "@shared/selectUser/table-header";
import TableHeaderCol from "@shared/selectUser/table-header-col";
import RowItem from "@shared/selectUser/table-row";
import {
  corrDBRoldId,
  corrDocControlRoleId,
  correspondenceAppId,
} from "@shared/staticValue";
import {
  errorHandle,
  generateUserRowItem,
  getDateWithOffSet,
  getFilesByType,
  getLastestIssuedItem,
  isFileNameInvalid,
} from "@shared/utils";
import _ from "lodash";
import { Observable } from "rxjs";
import environment from "src/environments/environment";

@Component({
  selector: "app-inReview",
  templateUrl: "./inReview.component.html",
  styleUrls: ["./inReview.component.scss"],
})
export default class InReviewComponent implements OnInit {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  entity: ICorrListItem;

  currentUser: string;

  defaultTab = "processing";

  docs: ICorrDocumentDownload[] = [];

  isDbAdmin = false;

  corr: CorrInReview;

  oldCorr: CorrInReview;

  canIssue = false;

  acknowledge = false;

  letterDate: Date | null = null;

  today: Date;

  enableSaveDraft = false;

  allUserTableRows: RowItem[] = [];

  ccUsersTableData: RowItem[] = [];

  ccUsersTableRows: RowItem[] = [];

  showSelectUser = false;

  tableRows: RowItem[] = [];

  firstOpen = true;

  tableHeader: TableHeaderCol[] = tableHeader;

  activeSave = false;

  correspondenceFile: FileModel[] = [];

  attachments: FileModel[] = [];

  oldCorrespondenceFile: FileModel[] = [];

  oldAttachments: FileModel[] = [];

  correspondenceFileNameValid = true;

  attachmentsNameValid = true;

  isManager = false;

  correspondenceFileType = CorrespondenceFileType;

  columnType = ColumnType;

  managerId: string;

  private removedFileIds: string[] = [];

  constructor(
    public router: Router,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public localContractUserService: LocalContractUserService,
    public activeModal: ModalContainerService,
    public correspondenceItemService: CorrespondenceItemService,
    public fileManagementService: FileManagementService,
    public correspondenceDocumentsService: CorrespondenceDocumentsService,
  ) {}

  ngOnInit(): void {
    const data: IViewPrepare | undefined =
      this.activatedRoute.snapshot.data.itemData;
    this.currentUser = this.authService.user.Id;
    this.today = new Date();
    this.today.setDate(new Date().getDate() + 1);
    if (data) {
      const { item, documents } = data;
      this.entity = item;
      this.docs = documents;
      const lastIssued = getLastestIssuedItem(this.entity);
      this.managerId = lastIssued?.ManagerId;
      this.oldCorr = new CorrInReview(this.entity.ContractId, this.entity);
      this.letterDate = this.oldCorr.correspondence_response.LetterDate
        ? new Date(
            getDateWithOffSet(this.oldCorr.correspondence_response.LetterDate),
          )
        : null;
      this.allUserTableRows = this.localContractUserService
        .getItems()
        .filter((user) => {
          return user.contract_user_application_role.find((role) => {
            return (
              role.ApplicationId === correspondenceAppId &&
              role.ApplicationRoleId !== corrDBRoldId &&
              role.ApplicationRoleId !== corrDocControlRoleId
            );
          });
        })
        .map((u) => {
          return generateUserRowItem<IContractUserWithUserInfo>(u);
        });
      this.ccUsersTableData = this.allUserTableRows.filter((u) => {
        return this.oldCorr.correspondence_cc_user.includes(u.id);
      });
      const documentForThis = documents.filter((doc) => {
        return doc.ReferenceId === this.oldCorr.correspondence_response.Guid;
      });
      this.oldCorrespondenceFile = getFilesByType(
        documentForThis,
        CorrespondenceFileType.CorrespondenceFile,
      );
      this.oldAttachments = getFilesByType(
        documentForThis,
        CorrespondenceFileType.Attachment,
      );

      this.correspondenceFile = JSON.parse(
        JSON.stringify(this.oldCorrespondenceFile),
      );
      this.attachments = JSON.parse(JSON.stringify(this.oldAttachments));
      this.corr = JSON.parse(JSON.stringify(this.oldCorr));
    }
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldCorr);
    const current = JSON.stringify(this.corr);
    const oldCorrespondenceFile = JSON.stringify(this.oldCorrespondenceFile);
    const currentCorrespondenceFile = JSON.stringify(this.correspondenceFile);
    const oldAttachments = JSON.stringify(this.oldAttachments);
    const currentAttachments = JSON.stringify(this.attachments);
    return (
      old !== current ||
      oldCorrespondenceFile !== currentCorrespondenceFile ||
      oldAttachments !== currentAttachments
    );
  }

  removeUserClick(row: RowItem): void {
    this.ccUsersTableData = this.ccUsersTableData.filter(
      (reviewer) => reviewer.id !== row.id,
    );
    this.corr.correspondence_cc_user = this.ccUsersTableData.map((u) => {
      return u.id;
    });
    this.enableSaveDraft = this.hasChanges();
  }

  openWindowClick(): void {
    this.activeSave = false;

    this.tableRows = this.allUserTableRows
      .filter((user) => {
        return (
          user.id !== this.managerId &&
          !this.corr.correspondence_cc_user.includes(user.id)
        );
      })
      .map((user) => {
        user.checked = false;
        return user;
      });

    this.showSelectUser = true;
  }

  closeWindow(e?: RowItem[]): void {
    if (this.firstOpen || !e) {
      this.firstOpen = false;
    } else if (this.activeSave) {
      if (e && Array.isArray(e) && e.length > 0) {
        this.ccUsersTableData = this.ccUsersTableData.concat(e);
        this.corr.correspondence_cc_user = this.ccUsersTableData.map((u) => {
          return u.id;
        });

        this.firstOpen = true;
        this.showSelectUser = false;
        this.enableSaveDraft = this.hasChanges();
      } else {
        const modalInstance = this.activeModal.open(NotificationComponent);
        modalInstance.instance.theme = "light";
        modalInstance.instance.title = "Notification";
        modalInstance.instance.body = "Are you sure you would like to cancel?";

        modalInstance.result.then(async (result) => {
          this.firstOpen = true;
          if (result === 1) {
            this.showSelectUser = false;
          }
        });
      }
    } else {
      this.firstOpen = true;
      this.showSelectUser = false;
    }
  }

  back(): void {
    if (this.hasChanges()) {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Cancel editing?";
      modalInstance.instance.body = "Your changes will not be saved.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.refreshEntity();
          this.router.navigateByUrl(`${this.entity.ContractId}/list`);
        }
      });
    } else {
      this.router.navigateByUrl(`${this.entity.ContractId}/list`);
    }
  }

  IsValid(): boolean {
    if (this.corr.correspondence_response.WantToResponse) {
      return (
        !_.isEmpty(this.corr.correspondence_response.LetterDate) &&
        this.correspondenceFile.length > 0 &&
        this.isFileNameValid()
      );
    }
    return true;
  }

  submit(): void {
    this.canIssue = true;

    const validation = this.IsValid();

    if (!validation) {
      return;
    }

    if (validation) {
      if (this.hasFilesUploading()) {
        this.showFileProgressNotification();
      } else {
        const modalInstance = this.activeModal.open(NotificationComponent);
        modalInstance.instance.theme = "light";
        modalInstance.instance.title = "Submit Correspondence?";
        modalInstance.instance.body =
          "Once it's submitted, you will no longer be able to edit.";

        modalInstance.result.then((result) => {
          if (result === 1) {
            this.corr.correspondence_response.IsDraft = false;
            this.update();
          }
        });
      }
    }
  }

  saveDraft(): void {
    if (!this.hasChanges()) return;
    if (!this.isFileNameValid()) return;

    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Save as Draft?";
      modalInstance.instance.body = "Your Submittal will save as draft.";

      modalInstance.result.then(async (result) => {
        if (result === 1) {
          this.corr.correspondence_response.IsDraft = true;

          this.update();
        }
      });
    }
  }

  update(): void {
    this.loadingService.start();
    if (this.corr.correspondence_response.WantToResponse) {
      this.corr.correspondence_response.Note = null;
    } else {
      this.corr.correspondence_response.ReferenceId = null;
      this.corr.correspondence_response.Response = null;
      this.corr.correspondence_response.LetterDate = null;
    }
    this.correspondenceItemService.corrUpdate(this.corr).subscribe(
      async (r) => {
        await this.updateFiles();
        this.refreshEntity();
        this.loadingService.stop();
        this.router.navigateByUrl(`${this.entity.ContractId}/list`);
      },
      (error: HttpErrorResponse) => {
        this.refreshEntity();
        this.loadingService.stop();
        errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  refreshEntity(): void {
    this.oldCorr = this.corr;
    this.oldCorrespondenceFile = this.correspondenceFile;
    this.oldAttachments = this.attachments;
  }

  setWantToResponse(e: boolean): void {
    this.corr.correspondence_response.WantToResponse = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setDate(e: Date | any): void {
    if (_.isNil(e) || e.type === "change") return;
    if (e.target?.value === "") {
      this.letterDate = null;
      this.corr.correspondence_response.LetterDate = null;
    } else {
      this.letterDate = new Date(e);
      this.corr.correspondence_response.LetterDate =
        this.letterDate.toISOString();
    }
  }

  setResponse(e: string): void {
    if (e) {
      this.corr.correspondence_response.Response = e;
      this.enableSaveDraft = this.hasChanges();
    }
  }

  setReferenceId(e: string): void {
    if (e) {
      this.corr.correspondence_response.ReferenceId = e;
      this.enableSaveDraft = this.hasChanges();
    }
  }

  setAcknowledge(e: boolean): void {
    this.acknowledge = e;
    this.enableSaveDraft = this.hasChanges();
  }

  async updateFiles(): Promise<void> {
    const allCurrentFiles = [...this.correspondenceFile, ...this.attachments];
    const allOldFiles = [
      ...this.oldCorrespondenceFile.map((f) => f.Guid),
      ...this.oldAttachments.map((f) => f.Guid),
    ];

    const filesToAdd = allCurrentFiles.filter((f) => {
      return !allOldFiles.includes(f.Guid) && f.Status === UploadStatus.LOADED;
    });

    const fileIds = allCurrentFiles.map((f) => {
      return f.Guid;
    });

    const filesToRemove = allOldFiles.filter((f) => {
      return !fileIds.includes(f);
    });

    const removeIds = [...new Set([...filesToRemove, ...this.removedFileIds])];
    const addIds = filesToAdd.map((f) => {
      return f.Guid;
    });
    console.log("service: filesToAdd", filesToAdd);
    console.log("service: fileIds", fileIds);
    console.log("service: filesToRemove", filesToRemove);
    console.log("service: removeIds", removeIds);

    if (addIds.length) {
      await this.correspondenceDocumentsService
        .setCorrespondenceDocumentAsSuccessful(addIds)
        .subscribe();
    }

    if (removeIds.length) {
      await this.correspondenceDocumentsService
        .removeCorrespondenceDocument(removeIds)
        .subscribe();
    }

    console.log("finished");

    return null;
  }

  importFile(e: FileModel[], type: string): void {
    if (!_.isEmpty(e)) {
      // console.log(type);
      e.forEach(async (item) => {
        if (item.Status === UploadStatus.UPLOADING && item.Percentage === 0) {
          const fileUploaded = new CorrDocumentUpload(
            item.Name,
            this.currentUser,
            type,
            CorrespondenceStatus.In_Review,
            this.corr.Guid,
            this.corr.correspondence_response.Guid ?? this.corr.tempId,
          );

          await this.correspondenceDocumentsService
            .createCorrespondenceDocument(fileUploaded)
            .toPromise()
            .then((r: ICorrDocumentUploadReturn) => {
              item.Guid = r.Guid;
              this.updateFileList(item, type);
              this.enableSaveDraft = this.hasChanges();
              if (environment.fileService === "azure") {
                AzureBlobService.uploadFile(r.URL, item);
              } else {
                this.fileManagementService.uploadFileToS3(r.URL, item);
              }
            });
        } else if (
          item.Status === UploadStatus.FAILED ||
          item.Status === UploadStatus.CANCELED
        ) {
          this.deleteFile(item, type);
        }
      });
    }
  }

  deleteFile(e: FileModel, type: string): void {
    this.updateFileList(e, type, true);
    this.enableSaveDraft = this.hasChanges();
  }

  updateFileList(item: FileModel, type: string, isRemove = false): void {
    let index = -1;

    switch (type) {
      case CorrespondenceFileType.CorrespondenceFile:
        index = this.correspondenceFile.findIndex((file) => {
          return file.Name === item.Name;
        });
        if (isRemove) {
          if (index !== -1) {
            this.correspondenceFile.splice(index, 1);
          }
        } else if (index === -1) {
          this.correspondenceFile.push(item);
          this.correspondenceFile = this.correspondenceFile.slice();
        }

        break;
      case CorrespondenceFileType.Attachment:
        index = this.attachments.findIndex((file) => {
          return file.Name === item.Name;
        });
        if (isRemove) {
          if (index !== -1) {
            this.attachments.splice(index, 1);
          }
        } else if (index === -1) {
          this.attachments.push(item);
          this.attachments = this.attachments.slice();
        }
        break;
      default:
        break;
    }
    if (isRemove) {
      this.removedFileIds.push(item.Guid);
    }
    this.isFileNameValid();
  }

  isFileNameValid(): boolean {
    this.correspondenceFileNameValid = true;
    this.correspondenceFile.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.correspondenceFileNameValid = false;
      }
    });
    this.attachmentsNameValid = true;
    this.attachments.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.attachmentsNameValid = false;
      }
    });
    return this.correspondenceFileNameValid && this.attachmentsNameValid;
  }

  hasFilesUploading(): boolean {
    return (
      this.correspondenceFile.some(
        (item) => item.Status === UploadStatus.UPLOADING,
      ) ||
      this.attachments.some((item) => item.Status === UploadStatus.UPLOADING)
    );
  }

  showFileProgressNotification(): void {
    const modalInstance = this.activeModal.open(NotificationComponent);

    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "File upload in progress";
    modalInstance.instance.body =
      "Please wait until all your files have been uploaded";
    modalInstance.instance.type = NotificationType.Information;

    modalInstance.result.then((result) => {
      return result;
    });
  }
}
