import { ICorrCreate, ICorrCreateInput } from "@api";

import TimeStampWithDraft from "./timeStampWithDraft";

export default class CorrCreateBase
  extends TimeStampWithDraft
  implements ICorrCreateInput
{
  public Guid?: string;

  public LetterDate?: string | null;

  public Reference?: string | null;

  public Subject?: string | null;

  public From?: string | null;

  public To?: string | null;

  public OrganizationReferenceNumber?: string | null;

  public Note?: string | null;

  public Priority?: number | null;

  constructor(item?: ICorrCreate, dbUserId?: string) {
    super(item);
    this.Guid = item?.Guid;
    this.LetterDate = item?.LetterDate;
    this.Reference = item?.Reference;
    this.Subject = item?.Subject;
    this.From = item ? item.From : dbUserId;
    this.To = item?.To;
    this.OrganizationReferenceNumber = item?.OrganizationReferenceNumber;
    this.Note = item?.Note;
    this.Priority = item?.Priority;
  }
}
