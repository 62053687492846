<div *ngIf="corr" class="maincontainer light-theme">
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel [entity]="entity" [docs]="docs" [defaultTab]="defaultTab"></leftPanel>
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button
                data-cy="back"
                innerClass="btn lg outline orange"
                (click)="back()"
              >
                back
              </aecom-button>
              <aecom-button
                [theme]="'light'"
                [disabled]="!hasChanges() ? true : null"
                innerClass="btn lg solid green"
                (click)="hasChanges() && save()"
              >
                save
              </aecom-button>
              <!-- <aecom-button
                data-cy="submit"
                innerClass="btn lg solid blue"
                (click)="submit()"
              >
                Submit
              </aecom-button> -->
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <aecom-form columns="6">
            <aecom-form-group
              columnSpan="6"
              columns="2"
              title="Processing"
              style="margin-bottom: 40px"
              class="matchToTab"
            >
              <aecom-form-item
                data-cy="requireResponse"
                label="Does this Correspondence specifically request a response?"
                columnSpan="1"
              >
                <div class="button-wrapper d-flex">
                  <aecom-button
                    id="requireResponseYes"
                    innerClass="btn solid btn-tab {{
                      corr.correspondence_issued.RequireResponse
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setRequireResponse(true)"
                  >
                    Yes
                  </aecom-button>

                  <aecom-button
                    id="requireResponseNo"
                    innerClass="btn solid btn-tab {{
                      !corr.correspondence_issued.RequireResponse
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setRequireResponse(false)"
                  >
                    No
                  </aecom-button>
                </div>
              </aecom-form-item>
              <aecom-form-item label="Due Date (Optional)" columnSpan="1" *ngIf="corr.correspondence_issued.RequireResponse; else skipItem">
                <aecom-date
                  id="due"
                  data-cy="due"
                  [weekendsOff]="true"
                  [notValidBefore]="today"
                  [theme]="'light'"
                  [ngValue]="dueDate"
                  (change)="setDate($event)"
                >
                </aecom-date>
              </aecom-form-item>
              <aecom-form-item
                data-cy="receivedToday"
                label="Was this Correspondence received today"
                columnSpan="1"
              >
                <div class="button-wrapper d-flex">
                  <aecom-button
                    id="receivedToadyYes"
                    innerClass="btn solid btn-tab {{
                      corr.correspondence_issued.ReceivedToday
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setReceivedToday(true)"
                  >
                    Yes
                  </aecom-button>

                  <aecom-button
                    id="receivedTodayNo"
                    innerClass="btn solid btn-tab {{
                      !corr.correspondence_issued.ReceivedToday
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setReceivedToday(false)"
                  >
                    No
                  </aecom-button>
                </div>
              </aecom-form-item>
              <aecom-form-item label="Received Date (Optional)" columnSpan="1" *ngIf="!corr.correspondence_issued.ReceivedToday; else skipItem">
                <aecom-date
                  id="receivedDate"
                  data-cy="receivedDate"
                  [theme]="'light'"
                  [ngValue]="receivedDate"
                  (change)="setReceivedDate($event)"
                  [notValidAfter]="today"
                >
                </aecom-date>
              </aecom-form-item>
              <aecom-form-item
                data-cy="isConfidential"
                label="Is this Correspondence confidential?"
                columnSpan="1"
              >
                <div class="button-wrapper d-flex">
                  <aecom-button
                    id="isConfidentialYes"
                    innerClass="btn solid btn-tab {{
                      corr.correspondence_issued.IsConfidential
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setIsConfidential(true)"
                  >
                    Yes
                  </aecom-button>

                  <aecom-button
                    id="isConfidentialNo"
                    innerClass="btn solid btn-tab {{
                      !corr.correspondence_issued.IsConfidential
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setIsConfidential(false)"
                  >
                    No
                  </aecom-button>
                </div>
              </aecom-form-item>
              <aecom-form-item columnSpan="1">
              </aecom-form-item>
              <aecom-form-item
                label="Correspondence Type (Optional)"
                columnSpan="1"
              >
                <aecom-dropdown
                  id="corrType"
                  data-cy="corrType"
                  [theme]="'light'"
                  placeholder="Select"
                  [data]="corrTypeList"
                  [selectedId]="corr.correspondence_issued.CorrespondenceTypeId"
                  [autocomplete]="true"
                  (ngValueChange)="setCorrType($event)"
                  (change)="setCorrType($event)"
                ></aecom-dropdown>
              </aecom-form-item>
              <aecom-form-item
                label="Key Words"
                columnSpan="1"
              >
                <bbj-tag id="topic" data-cy="topic" [(tagsIn)]="corr.correspondence_issued.Topics" [allTags]="allTags"></bbj-tag>
              </aecom-form-item>
              
            </aecom-form-group>

            <!-- <aecom-form-group
              columnSpan="6"
              columns="2"
              title="Distribution"
              style="margin-bottom: 40px"
            >
              <aecom-form-item
                label="Manager"
                columnSpan="1"
              >
                <aecom-dropdown
                  id="manager"
                  data-cy="manager"
                  [theme]="'light'"
                  placeholder="Select"
                  [data]="managersList"
                  [selectedId]="corr.correspondence_issued.ManagerId"
                  (ngValueChange)="setManager($event)"
                  [validate]="
                    canIssue &&
                    (!corr.correspondence_issued.ManagerId ||
                    corr.correspondence_issued.ManagerId.trim() === '')
                  "
                ></aecom-dropdown>
              </aecom-form-item>
              <aecom-form-item
                columnSpan="2"
                [label]="'Note to Manager (Optional)'"
                [property]="
                (corr.correspondence_issued.Note ? corr.correspondence_issued.Note.length : 0) +
                  '/1500 characters'
                "
              >
                <aecom-textbox
                  data-cy="note"
                  [theme]="'light'"
                  [(ngValue)]="corr.correspondence_issued.Note"
                  rows="6"
                  [maxlength]="1500"
                  [isMultiLine]="true"
                ></aecom-textbox>
              </aecom-form-item>
            </aecom-form-group> -->
            <aecom-form-group
              columnSpan="6"
              columns="1"
              title="CC (Optional)"
              style="position: relative"
            >
              <div style="width: 100%; height: 250px" class="noTableFilter">
                <aecom-table
                  [ngValue]="ccUsersTableData"
                  (ngValueChange)="ccUsersTableRows = $event"
                  [theme]="'light'"
                  innerClass="list"
                >
                  <tbody>
                    <tr
                      *ngFor="let bRow of ccUsersTableRows"
                      class="assignUser_tr_hover"
                    >
                      <ng-container *ngFor="let hCol of tableHeader">
                        <td *ngIf="hCol.Type !== columnType.Hide">
                          <span class="assignUser_td_Item">
                            {{ bRow[hCol.Name] }}
                          </span>
                        </td>
                      </ng-container>

                      <td>
                        <a
                          class="btn"
                          (click)="removeUserClick(bRow)"
                        >
                          <i
                            class="
                              icon
                              icon-social_user_remove
                              icon-orange icon-lg
                            "
                          ></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </aecom-table>

                <div
                  style="
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                  "
                  *ngIf="ccUsersTableData?.length === 0"
                >
                  <div class="noItem-body">
                    <div>
                      <div
                        class="
                          noItem-icon-row
                          icon
                          icon-social_user_team_big
                          icon-grey icon-xl
                        "
                      ></div>
                    </div>

                    <div class="noItem-title-row">None</div>
                  </div>
                </div>
              </div>
              <div
                style="
                  position: absolute;
                  top: 5px;
                  right: 0;
                  cursor: pointer;
                "
              >
                <div
                  data-cy="addCCUser"
                  class="formGroupManu"
                  (click)="openWindowClick()"
                >
                  + Add Users
                </div>
              </div>
            </aecom-form-group>
          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showSelectUser" class="up-contentcontainer">
  <div class="selectUserWindow" (clickOutside)="closeWindow()">
    <select-user
      data-cy="selectUser"
      [tableData]="tableRows"
      [title]="'ADD CC'"
      (close)="closeWindow($event)"
      [(firstOpen)]="firstOpen"
      [(activeSave)]="activeSave"
    ></select-user>
  </div>
</div>

<ng-template #skipItem>
  <aecom-form-item columnSpan="1"></aecom-form-item>
</ng-template>
