export default class TimeStamp {
  public DateCreated?: string;

  public CreatedBy?: string;

  public DateUpdated?: string;

  public UpdatedBy?: string;

  constructor(item?: TimeStamp) {
    this.DateCreated = item?.DateCreated;

    this.CreatedBy = item?.CreatedBy;

    this.DateUpdated = item?.DateUpdated;

    this.UpdatedBy = item?.UpdatedBy;
  }
}
