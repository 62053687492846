<div class="responseBox" *ngIf="item">
  <div class="responseHeader">
    <span>{{ item.Title }}</span>
    <div
      class="responseHeaderCollapse"
      (click)="item.Collapse = !item.Collapse"
    >
      <i
        [ngClass]="
          item.Collapse
            ? 'icon-action_arrow_down'
            : 'icon-action_arrow_up'
        "
        class="icon icon-blue icon-lg"
      ></i>
    </div>
  </div>
  <div class="responseContent" *ngIf="!item.Collapse">
    <div class="responseBody row">
      <div class="col-6">
        <div class="responseBodySubTitle">Response requested within letter?</div>
        <div class="responseBodySubContent">
          <div [ngClass]="
          item.RequestResponse
            ? 'greenBox'
            : 'redBox'
        ">{{item.RequestResponse ? 'Yes' : 'No'}}</div>
        </div>
      </div>
      <div class="col-6">
        <div class="responseBodySubTitle">Due Date</div>
        <div class="responseBodySubContent">{{item.DueDate}}</div>
      </div>
    </div>
    <div class="responseBody">
      <div class="responseBodySubTitle">Note</div>
      <div class="responseBodySubContent">{{item.Note}}</div>
    </div>
  </div>
</div>