import { CorrespondenceResponse, ICorrResponseInput } from "@api";

import TimeStampWithDraft from "./timeStampWithDraft";

export default class CorrInReviewBase
  extends TimeStampWithDraft
  implements ICorrResponseInput
{
  public Guid?: string;

  public WantToResponse?: boolean | null;

  public LetterDate?: string | null;

  public Response?: string | null;

  public ReferenceId?: string | null;

  public Note?: string | null;

  constructor(item?: CorrespondenceResponse) {
    super(item);
    this.Guid = item?.Guid;
    this.WantToResponse = item?.WantToResponse ?? true;
    this.LetterDate = item?.LetterDate;
    this.Response = item?.Response;
    this.ReferenceId = item?.ReferenceId;
    this.Note = item?.Note;
  }
}
