/* eslint-disable import/no-extraneous-dependencies */
import { FileModel, UploadStatus } from "@aecom/core";
import {
  BlockBlobClient,
  BlobDownloadResponseParsed,
} from "@azure/storage-blob";
import { Injectable } from "@angular/core";
import mime from "mime";

@Injectable({
  providedIn: "root",
})
export default class AzureBlobService {
  static async uploadFile(url: string, file: FileModel): Promise<void> {
    const blockBlobClient = new BlockBlobClient(url);
    const type = mime.getType(file.Name) || "application/octet-stream";
    await blockBlobClient.uploadData(file.File, {
      blobHTTPHeaders: { blobContentType: type },
      onProgress: (ev) => {
        file.Percentage = ev.loadedBytes / file.File.size;
        file.Percentage = Math.floor(file.Percentage * 100);

        if (file.Percentage >= 100) {
          file.Status = UploadStatus.LOADED;
        }
      },
    });
  }

  static async downloadBlobToFile(
    item: FileModel,
  ): Promise<BlobDownloadResponseParsed> {
    const blobClient = new BlockBlobClient(item.DownloadURL);
    return blobClient.download();
  }
}
