import { ICorrListItem, ICorrResponseStepItem } from "@api";
import { v4 as uuidv4 } from 'uuid';
import CorrespondenceUpdateBase from "./CorrespondenceUpdateBase";
import CorrInReviewBase from "./corrInReviewBase";

export default class CorrInReview
  extends CorrespondenceUpdateBase
  implements ICorrResponseStepItem
{
  public correspondence_response: CorrInReviewBase;

  public correspondence_cc_user?: string[];

  public tempId?: string;

  constructor(contractId: string, item: ICorrListItem) {
    super(contractId, item);

    if (
      item.correspondence_response &&
      item.correspondence_response.length > 0
    ) {
      const lastItem = item.correspondence_response.sort(
        (a, b) =>
          new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime(),
      )[0];
      if (lastItem.UpdatedBy === item.UpdatedBy && lastItem.IsDraft === true) {
        this.correspondence_response = new CorrInReviewBase(lastItem);
      }
    }
    if (!this.correspondence_response) {
      this.tempId = uuidv4();
      this.correspondence_response = new CorrInReviewBase();
    }
    if (item.correspondence_cc_user) {
      this.correspondence_cc_user = item.correspondence_cc_user.map((user) => {
        return user.UserId;
      });
    } else {
      this.correspondence_cc_user = [];
    }
  }
}
