import { FileModel } from "@aecom/core";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import AzureBlobService from "@services/azureBlob.service";
import environment from "src/environments/environment";
import FileModelWithSize from "./fileModelWithSize";


@Component({
  selector: "file-list",
  templateUrl: "./fileList.component.html",
  styleUrls: ["./fileList.component.scss"]
})
export default class FileListComponent implements OnInit {
  @Input() fileList: FileModel[];

  @Input() title?: string;

  @Input() showDetail = false;

  @Input() showVersion = false;

  @Input() showSeparator = true;

  @Input() allowMovingFiles = true;

  @Input() maxCharacters = 110;

  @Output() movedFile = new EventEmitter<FileModel>();

  @Output() movedFiles = new EventEmitter<FileModel[]>();

  download = FileListComponent.download;

  imageExtentions = [".bmp", ".png", ".jpg", ".jpeg", ".gif", ".tiff", ".svg"];

  public files: FileModelWithSize[];

  async ngOnInit(): Promise<void> {
    this.files = this.fileList.map((item, i) => {
      const itemWithSize = item as FileModelWithSize;
      itemWithSize.Size = this.getMegabytes(item.Size ?? 0);
      return itemWithSize;
    });
  }

  getFileIcon(filename: string): string {
    const name = filename.toLowerCase();
    return this.imageExtentions.some((a) => name.includes(a))
      ? "icon icon-image_doc icon-cus_green"
      : "icon icon-blank_doc icon-cus_green";
  }

  moveFile(item: FileModel): void {
    // console.log(item);
    this.movedFile.emit(item);
  }

  moveFiles(): void {
    // console.log(item);
    this.movedFiles.emit(this.fileList);
  }

  static async download(item: FileModel): Promise<void> {
    if (environment.fileService === "azure") {
      const body = await AzureBlobService.downloadBlobToFile(item);
      const blob = await body.blobBody;
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = item.Name;
      link.click();
    } else {
      window.open(item.DownloadURL, "_blank");
    }
  }

  async downloadAll(): Promise<void> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    if (environment.fileService === "azure") {
      const bodys = await Promise.all(this.fileList.map((item) => {
        return AzureBlobService.downloadBlobToFile(item);
      }));
      const blobs = await Promise.all(bodys.map((body) => {
        return body.blobBody;
      }));
      blobs.forEach((item, i) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(item);
        link.download = this.fileList[i].Name;
        link.click();
      });
    } else {
      this.fileList.forEach((item) => {
        window.open(item.DownloadURL, "_blank");
      });
    }
  }

  getFormatDate(dateString: string): string {
    return `${new Date(dateString)
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      })
      .replace(/ /g, "-")
      .replace(/,/g, "")} ${new Date(dateString).toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit"
    })}`;
  }

  // getFileSize(url: string): string {
  //   let blob = await fetch("http://localhost:4200/assets/test.pdf").then((r) => r.blob());
  //     const file = new File([blob], "testFile");
  //     console.log("file to download", file.size);
  // }

  getMegabytes(size): string {
    return `${(Math.round(+size / 1024) / 1024).toFixed(2)}MB`;
  }
}
