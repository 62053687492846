import { Injectable } from "@angular/core";
import IContractUserWithUserInfo from "@models/IContractUserWithUserInfo";
import { correspondenceAppId } from "@shared/staticValue";
import { BehaviorSubject, Observable } from "rxjs";
import { ContractUserApplicationRole } from "../api-generated";
import BaseService from "./base.service";

@Injectable({
  providedIn: "root",
})
export default class LocalContractUserService extends BaseService<IContractUserWithUserInfo> {
  public currentUserContractRole: string;

  public defaultDoc: string | null;

  protected contractUsersSubject = new BehaviorSubject<
    IContractUserWithUserInfo[]
  >(null);

  contractUsers$: Observable<IContractUserWithUserInfo[]> =
    this.contractUsersSubject.asObservable();

  getItems(): IContractUserWithUserInfo[] {
    return this.contractUsersSubject.getValue();
  }

  public setItems(item: IContractUserWithUserInfo[]): void {
    this.contractUsersSubject.next(item);
  }

  public getUserById(userId: string): IContractUserWithUserInfo | undefined {
    return this.getUsers().find((user: IContractUserWithUserInfo) => {
      return user.UserId === userId;
    });
  }

  public getUsers(
    userTypes: string[] = [],
    excludeUserTypes: string[] = [],
  ): IContractUserWithUserInfo[] {
    const users = this.contractUsersSubject
      .getValue()
      .map((user: IContractUserWithUserInfo) => {
        user.contract_user_application_role =
          user.contract_user_application_role.filter(
            (r) => r.ApplicationId == correspondenceAppId,
          );

        return user;
      })
      .filter((user: IContractUserWithUserInfo) => {
        return user.contract_user_application_role?.find(
          (userApplicationRole: ContractUserApplicationRole) => {
            if (excludeUserTypes.length) {
              return (
                userApplicationRole.ApplicationId === correspondenceAppId &&
                !excludeUserTypes.find((role) =>  role === userApplicationRole.ApplicationRoleId)
              );
            }

            return userApplicationRole.ApplicationId === correspondenceAppId;
          },
        );
      });

    if (!userTypes.length) {
      return users;
    }

    return users.filter((user) => {
      return user.contract_user_application_role?.find(
        (userApplicationRole: ContractUserApplicationRole) => {
          return userTypes.find((role) => role === userApplicationRole.ApplicationRoleId);
        },
      );
    });
  }

  public getUserRoleById(userId: string): string | undefined {
    return this.getUserById(userId)?.contract_user_application_role?.find(
      (cuar) => {
        return cuar.ApplicationId === correspondenceAppId;
      },
    )?.ApplicationRoleId;
  }
}
