import { CorrespondenceResponse } from "@api";
import { correspondenceAppId, NA } from "@shared/staticValue";
import IContractUserWithUserInfo from "./IContractUserWithUserInfo";

export default class ManagerResponseDetailItem {
  public Title: string;

  public Note: string;

  public Collapse: boolean;

  constructor(
    managerId: string,
    item: CorrespondenceResponse,
    users: IContractUserWithUserInfo[],
  ) {
    const contractUser = users.find((u) => {
      return u.UserId === managerId;
    });
    const role = contractUser.contract_user_application_role.find((r) => {
      return r.ApplicationId === correspondenceAppId;
    })?.application_role?.Role;
    const name = contractUser.UserInfo?.displayName;
    this.Title = role && name ? `${name}  |  ${role}` : NA;
    this.Note = item.Note ?? NA;
    this.Collapse = false;
  }
}
