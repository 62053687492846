import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import AuthService from "@auth/auth.service";

@Injectable({
  providedIn: "root",
})
export default class WaitForUserGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(): Promise<boolean> {
    return this.authService
      .getUser()
      .toPromise()
      .then(this.authService.waitForLogin.bind(this.authService))
      .then(() => {
        // console.log("User Found");
        return true;
      });
  }
}
